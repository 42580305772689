import { Done, Launch } from "@mui/icons-material";
import {
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

type OnboardingItemProps = {
  icon: React.ReactNode;
  title: string;
  route: string;
  isSetUp: boolean;
};

export const OnboardingItem: React.FC<OnboardingItemProps> = ({
  icon,
  title,
  route,
  isSetUp,
}) => {
  const [t] = useTranslation();

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(route);
  }, [navigate, route]);

  return (
    <>
      <ListItem>
        <ListItemAvatar>{icon}</ListItemAvatar>
        <ListItemText>{title}</ListItemText>
        {isSetUp && (
          <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <Typography variant="button" color="success">
              {t("general.labels.done")}
            </Typography>
            <Done color="success" />
          </Stack>
        )}
        {!isSetUp && (
          <Button variant="contained" endIcon={<Launch />} onClick={onClick}>
            {t("general.buttons.add")}
          </Button>
        )}
      </ListItem>
    </>
  );
};
