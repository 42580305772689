const isEmpty = (obj: any): boolean => {
  return (
    obj != null && typeof obj === "object" && Object.keys(obj).length === 0
  );
};

export const objectsEqual = (object1: any, object2: any) => {
  if (isEmpty(object1) || isEmpty(object2)) {
    return;
  }
  return typeof object1 === "object" && Object.keys(object1).length > 0
    ? Object.keys(object1).length === Object.keys(object2).length &&
        Object.keys(object1).every((property) =>
          objectsEqual(object1[property], object2[property]),
        )
    : object1 === object2;
};

export const arraysEqual = (array1: any[], array2: any[]) =>
  array1.length === array2.length &&
  array1.every((obj, idx) => objectsEqual(obj, array2[idx]));
