import { IMPORT_SOURCE_RLP } from "@ero/app-common/enums/parcelImport";
import { ImportResponseBody } from "@ero/app-common/v2/routes/models/import";
import { Phone, Publish } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Loader } from "Components";
import { SelectWithAutoComplete } from "Components/FormikAutocomplete/SelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";
import { useAllCustomerOptions } from "Hooks/customerOptions";
import { FieldImportValuesType } from "Types";
import { Form, Formik } from "formik";
import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ImportSourceSelection } from "./components/importSourceSelection";
import { StateInputs } from "./components/stateInputs";
import { useStateOptions } from "./hooks/useStateOptions";
import { useUploadProgress } from "./hooks/useUploadProgress";

interface ParcelImportFormPropsType {
  onSuccess: (responseData: ImportResponseBody) => void;
  hideCustomerAutocomplete?: boolean;
  externalAccessToken?: string;
}

const initialValues: FieldImportValuesType = {};

export const ParcelImportForm: React.FC<ParcelImportFormPropsType> = ({
  onSuccess,
  hideCustomerAutocomplete,
  externalAccessToken,
}) => {
  const [t] = useTranslation();
  const { isUploading, uploadFiles } = useUploadProgress((responseData) => {
    onSuccess(responseData);
  }, externalAccessToken);

  const { fetchCustomerOptions } = useAllCustomerOptions();

  const states = useStateOptions();

  const forwardedRef = useRef(null);

  const validationSchema = useMemo(() => {
    const baseValidationSchemaShape = {
      state: Yup.string().required(t("general.validationErrors.fieldRequired")),
      type: Yup.string().required(t("general.validationErrors.fieldRequired")),
      flurstuecksDaten: Yup.mixed().when("type", {
        is: IMPORT_SOURCE_RLP.FLORLP,
        then: (schema) =>
          schema.required(t("general.validationErrors.fieldRequired")),
      }),
      schlagDaten: Yup.mixed().required(
        t("general.validationErrors.fieldRequired"),
      ),
      wipDaten: Yup.mixed(),
    };
    let validationSchemaShape;
    if (!hideCustomerAutocomplete) {
      validationSchemaShape = {
        ...baseValidationSchemaShape,
        customer: Yup.number()
          .positive()
          .required(t("general.validationErrors.fieldRequired")),
      };
    } else {
      validationSchemaShape = baseValidationSchemaShape;
    }
    return Yup.object().shape(validationSchemaShape);
  }, [hideCustomerAutocomplete, t]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={uploadFiles}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, isValid, dirty }) => (
        <Form encType="multipart/form-data">
          <DialogContent dividers>
            <Stack>
              {!hideCustomerAutocomplete && (
                <Tooltip title={t("parcels.upload.selectcustomer")}>
                  <SelectWithAutoComplete
                    name="customer"
                    required
                    fetchOptions={fetchCustomerOptions}
                    label={t("general.fields.customer")}
                    sx={{ mt: 1, mb: 1 }}
                    disableClearable
                  />
                </Tooltip>
              )}
              <Tooltip
                title={
                  hideCustomerAutocomplete
                    ? t("parcels.upload.selectregion.external")
                    : t("parcels.upload.selectregion.internal")
                }
              >
                <StaticSelectWithAutoComplete
                  label={t("parcels.upload.state")}
                  required
                  name="state"
                  options={states}
                  sx={{ mt: 1, mb: 1 }}
                  disableClearable
                />
              </Tooltip>
              <Tooltip title={t("parcels.upload.importTypeHint")}>
                <ImportSourceSelection ref={forwardedRef} />
              </Tooltip>
              <StateInputs state={values.state} importSource={values.type} />
            </Stack>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "space-between" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption">
                {t("parcels.contactText")}
              </Typography>
              <Phone />
              <Typography variant="caption">
                {import.meta.env.VITE_ERO_SERVICE_PHONE}
              </Typography>
            </Stack>
            <Button
              variant={"contained"}
              type="submit"
              disabled={!isValid || !dirty || isUploading}
              startIcon={<Publish />}
            >
              {isUploading && <Loader size={30} />}
              {t("general.buttons.next")}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
