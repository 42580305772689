import { type DriversMessage } from "@ero/app-common/util/Websocket";
import { type MachineResponseBody } from "@ero/app-common/v2/routes/models/machine";
import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { type UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import { SelectionTypeEnum } from "Screens/dashboard/constants";
import { Coordinate, type MachinesMessage } from "Types";
import { type Action, type UnknownAction } from "redux";
import { ParcelFilters } from "./reducer";

export enum DASHBOARD_ACTION_TYPES {
  DASHBOARD_CANCEL_UPDATE = "dashboard/cancel-update",
  DASHBOARD_INITIAL_REQUEST = "dashboard/initial-request",
  DASHBOARD_INITIAL_SUCCESS = "dashboard/initial-success",
  SET_ERROR = "dashboard/set-error",
  SET_LOADING = "dashboard/set-loading",
  SET_MACHINES = "dashboard/set-machines",
  SET_PARCELS = "dashboard/set-parcels",
  SET_SELECTION_TYPE = "dashboard/set-dashboard-type",
  SET_SELECTION_TYPE_SUCCESS = "dashboard/set-dashboard-type-success",
  SET_SUCCESS = "dashboard/set-success",
  UPDATE_MACHINE_LIVE = "dashboard/update-machine-live",
  UPDATE_PARCEL = "dashboard/update-parcel",
  GET_DRIVERS = "dashboard/get-drivers",
  SET_DRIVERS = "dashboard/set-drivers",
  UPDATE_DRIVER_LIVE = "dashboard/update-driver-live",
  SET_SHOW_DRIVERS_TRACK = "dashboard/set-show-drivers-track",
  REMOVE_DRIVERS_SUBSCRIPTION = "dashboard/remove-drivers-subscription",
  SET_CENTER = "dashboard/set-center",
  SET_ZOOM = "dashboard/set-zoom",
  SET_PARCEL_FILTERS = "dashboard/set-parcel-filter",
  RESET_PARCEL_FILTERS = "dashboard/reset-parcel-filter",
}

export interface ISetSelectionType extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.SET_SELECTION_TYPE;
  payload: { type: SelectionTypeEnum };
}

export interface ISetSelectionTypeSuccess extends Action {
  type: DASHBOARD_ACTION_TYPES.SET_SELECTION_TYPE_SUCCESS;
  payload: { type: SelectionTypeEnum };
}

export interface IDashboardInitialRequest extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.DASHBOARD_INITIAL_REQUEST;
}

export interface IDashboardInitialSuccess extends Action {
  type: DASHBOARD_ACTION_TYPES.DASHBOARD_INITIAL_SUCCESS;
}

export interface IDashboardCancelUpdate extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.DASHBOARD_CANCEL_UPDATE;
}

export interface ISetLoading extends Action {
  type: DASHBOARD_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccess extends Action {
  type: DASHBOARD_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetError extends Action {
  type: DASHBOARD_ACTION_TYPES.SET_ERROR;
}

export interface ISetMachines extends Action {
  type: DASHBOARD_ACTION_TYPES.SET_MACHINES;
  payload: { list: MachineResponseBody[] };
}

export interface ISetParcels extends Action {
  type: DASHBOARD_ACTION_TYPES.SET_PARCELS;
  payload: { list: ParcelResponseBody[] };
}

export interface IUpdateMachineLive extends Action {
  type: DASHBOARD_ACTION_TYPES.UPDATE_MACHINE_LIVE;
  payload: { message: MachinesMessage };
}

export interface IGetDrivers extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.GET_DRIVERS;
}

export interface ISetDrivers extends Action {
  type: DASHBOARD_ACTION_TYPES.SET_DRIVERS;
  payload: { data: UserResponseBody[] };
}

export interface IUpdateDriverLive extends Action {
  type: DASHBOARD_ACTION_TYPES.UPDATE_DRIVER_LIVE;
  payload: { message: DriversMessage };
}

export interface ISetShowDriversTrack extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.SET_SHOW_DRIVERS_TRACK;
  payload: { showDriversTrack: boolean };
}

export interface IRemoveDriversSubscription extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.REMOVE_DRIVERS_SUBSCRIPTION;
}

export interface ISetCenter extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.SET_CENTER;
  payload: { center: Coordinate };
}

export interface ISetZoom extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.SET_ZOOM;
  payload: { zoom: number };
}

export interface ISetParcelFilters extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.SET_PARCEL_FILTERS;
  payload: ParcelFilters | undefined;
}

export interface IResetParcelFilters extends UnknownAction {
  type: DASHBOARD_ACTION_TYPES.RESET_PARCEL_FILTERS;
}

export type DashboardAction =
  | ISetSelectionType
  | ISetSelectionTypeSuccess
  | IDashboardInitialRequest
  | IDashboardInitialSuccess
  | IDashboardCancelUpdate
  | ISetLoading
  | ISetSuccess
  | ISetMachines
  | IUpdateMachineLive
  | ISetParcels
  | ISetSuccess
  | ISetError
  | ISetDrivers
  | IUpdateDriverLive
  | ISetShowDriversTrack
  | IRemoveDriversSubscription
  | ISetCenter
  | ISetZoom
  | ISetParcelFilters
  | IResetParcelFilters;
