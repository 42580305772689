import { type ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { Grid2 } from "@mui/material";
import {
  CenterControlV2,
  FullScreenControlV2,
  Loader,
  MapV2,
  TrackControlV2,
  ZoomControlV2,
} from "Components";
import { BottomControls } from "Components/map/components/controls/bottomControls";
import { TopControls } from "Components/map/components/controls/topControls";
import { ROUTES } from "Constants";
import { useGeoposition } from "Hooks/useGeoposition";
import { AppState } from "Store";
import {
  dashboardCancelUpdate,
  dashboardInitialRequest,
  setCenter,
  setZoom,
} from "Store/dashboard";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DriversLiveTrack, InfoWindows, Machines, Parcels } from "./components";
import { DashboardLoader } from "./components/Loader";
import { OnboardingModal } from "./components/onboardingModal/OnBoardingModal";
import { ParcelFilters } from "./components/parcelFilters/ParcelFilters";
import { useSelection } from "./hooks/selection";

export const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { geoposition, watching, addWatcher, removeWatcher } = useGeoposition();

  const map = useRef<google.maps.Map>();

  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);

  const {
    selectionType: selectionTypeState,
    loading,
    center: lastCenter,
    zoom,
    machinesList,
    parcelsList,
    drivers,
    showDriversTrack,
  } = useSelector((state: AppState) => state.dashboard);

  const { companyData } = useSelector((store: AppState) => store.auth);

  const initialCenter = useMemo(
    () => lastCenter ?? companyData.location,
    // do not add dependencies so the initial center is not recomputed each and every time lastCente changes
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onMapLoad = useCallback((mapInstance: google.maps.Map) => {
    map.current = mapInstance;
  }, []);

  const onMapClick = useCallback(() => {
    setShowInfoWindow(false);
  }, []);

  const onMapCenterChanged = useCallback(() => {
    const currentCenter = map.current?.getCenter();
    if (currentCenter) {
      dispatch(setCenter(currentCenter.toJSON()));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetCenter = useCallback(() => {
    const position = companyData.location || geoposition;
    if (position) {
      map.current?.setCenter(position);
    }
  }, [companyData.location, geoposition]);

  const onMapZoomChanged = useCallback(
    (zoom?: number) => {
      if (zoom) {
        map.current?.setZoom(zoom);
        dispatch(setZoom(zoom));
        return;
      }

      const currentZoom = map.current?.getZoom();
      if (currentZoom) dispatch(setZoom(currentZoom));
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [map],
  );

  useEffect(() => {
    dispatch(dashboardInitialRequest());

    if (!companyData.location) addWatcher();

    return () => {
      dispatch(dashboardCancelUpdate());
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (watching) removeWatcher();
    };
  }, [watching, removeWatcher]);

  const {
    selectedId,
    machineItemOnClick,
    parcelItemOnClick,
    driversTrackBtnOnClick,
  } = useSelection(
    selectionTypeState,
    machinesList,
    parcelsList,
    setShowInfoWindow,
  );

  const handleEditMapObject = useCallback(
    (
      type: "machine" | "parcel" | "job",
      id: number,
      orderId: number | undefined = undefined,
    ) => {
      if (type === "job") {
        navigate(`${ROUTES.MAIN.ORDERS}/${orderId}/jobs/${id}`);
      } else {
        const route =
          type === "machine" ? ROUTES.MAIN.MACHINES : ROUTES.MAIN.PARCELS;
        navigate(`${route}`, { state: { id } });
      }
    },
    [navigate],
  );

  const mapBounds = useMemo(() => {
    if (google.maps.LatLngBounds) {
      const bounds = new google.maps.LatLngBounds();
      parcelsList.forEach((parcel) => {
        if ((parcel.shape?.length ?? 0) > 0) {
          parcel.shape?.forEach((coord) => bounds.extend(coord));
        } else if (parcel.position) {
          bounds.extend(parcel.position);
        }
      });
      if (companyData.location) {
        bounds.extend(companyData.location);
      }
      return bounds;
    }
  }, [companyData.location, parcelsList]);

  useEffect(() => {
    if (mapBounds && !mapBounds.isEmpty()) {
      // directly calling fitBounds can lead to race conditions with removing parcel polygons
      // from the map (due to zoom change and removing react components vs. the way,
      // react-google-maps/api handles the SDK), so give the parcels time to re-render before
      // fitting the map to the new bounds
      setTimeout(() => void map.current?.fitBounds(mapBounds), 100);
    }
  }, [mapBounds]);

  return (
    <>
      <Grid2 container height="100%" sx={{ overflow: "hidden" }}>
        <Grid2 sx={{ width: "100%", height: "100%" }}>
          <MapV2
            id="dashboard-map"
            center={initialCenter}
            zoom={zoom}
            onLoad={onMapLoad}
            onClick={onMapClick}
            onCenterChanged={onMapCenterChanged}
            onZoomChanged={onMapZoomChanged}
          >
            <DashboardLoader loading={loading} />
            <ParcelFilters mapContainer={map.current?.getDiv()} />
            <Parcels
              zoom={zoom}
              parcels={parcelsList}
              itemOnClick={parcelItemOnClick}
            />
            <Machines
              machines={machinesList}
              itemOnClick={machineItemOnClick}
            />
            {showDriversTrack && <DriversLiveTrack drivers={drivers} />}
            <TopControls>
              <FullScreenControlV2 mapRef={map} />
              <TrackControlV2
                showTrack={showDriversTrack}
                onClick={driversTrackBtnOnClick}
              />
            </TopControls>
            <BottomControls>
              <CenterControlV2 onCenter={resetCenter}></CenterControlV2>
              <ZoomControlV2 zoom={zoom} onZoomChanged={onMapZoomChanged} />
            </BottomControls>
            <InfoWindows
              machines={machinesList}
              parcels={
                parcelsList as (Partial<ParcelResponseBody> & { _id: number })[]
              }
              showInfoWindow={showInfoWindow}
              setShowInfoWindow={setShowInfoWindow}
              selectedId={selectedId}
              selectionType={selectionTypeState}
              handleEditMapObject={handleEditMapObject}
            />
          </MapV2>
        </Grid2>
        {loading && <Loader />}
      </Grid2>
      <OnboardingModal />
    </>
  );
};
