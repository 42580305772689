import { CustomerOutside } from "@ero/app-common/models/Customer";
import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type Action, type UnknownAction } from "redux";
import { RequestBody, type GetTableDataParams } from "Types";

export enum CUSTOMER_PERSONS_ACTION_TYPES {
  GET_INITIAL_CUSTOMER_PERSONS_REQUEST = "customer-persons/get-customer-persons-initial-request",
  GET_INITIAL_CUSTOMER_PERSONS_SUCCESS = "customer-persons/get-customer-persons-initial-success",
  GET_INITIAL_CUSTOMER_PERSONS_ERROR = "customer-persons/get-customer-persons-error",
  GET_CUSTOMER_PERSONS_REQUEST = "customer-persons/get-customer-persons-request",
  GET_CUSTOMER_PERSONS_SUCCESS = "customer-persons/get-customer-persons-success",
  CREATE_CUSTOMER_PERSON = "customer-persons/create-customers-persons",
  UPDATE_CUSTOMER_PERSON = "customer-persons/update-customers-persons",
  DELETE_CUSTOMER_PERSON = "customer-persons/delete-customers-persons",
  SET_LOADING = "customer-persons/set-loading",
  SET_SUCCESS = "customer-persons/set-success",
  RESET_META = "customer-persons/reset-meta",
  SET_ERROR = "customer-persons/set-error",
  SET_TABLE_HIDDEN_COLUMNS = "customer-persons/set-table-hidden-columns",
  RESET_STATE = "customer-persons/reset-state",
  CHANGE_COLUMNS_ORDER = "customer-persons/change-columns-order",
}

export interface ISetLoadingAction extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends UnknownAction {
  type: CUSTOMER_PERSONS_ACTION_TYPES.RESET_META;
}

export interface IGetInitialCustomerPersonsRequestAction extends UnknownAction {
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_REQUEST;
  payload: {
    params: GetTableDataParams;
    customerId: number;
  };
}

export interface IGetInitialCustomerPersonsSuccessAction extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_SUCCESS;
  payload: {
    customerPersons: CustomerOutside["persons"];
    maxCount: number;
    customerId: number;
  };
}

export interface IGetInitialCustomerPersonsErrorAction extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_INITIAL_CUSTOMER_PERSONS_ERROR;
}

export interface IGetCustomerPersonsRequestAction extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_CUSTOMER_PERSONS_REQUEST;
  payload: {
    params: GetTableDataParams;
    search?: SearchSpec;
    withSuccess?: boolean;
  };
}

export interface IGetCustomerPersonsSuccessAction extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.GET_CUSTOMER_PERSONS_SUCCESS;
  payload: {
    customerPersons: CustomerOutside["persons"];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
  };
}

export interface ICreateCustomerPersonAction extends UnknownAction {
  type: CUSTOMER_PERSONS_ACTION_TYPES.CREATE_CUSTOMER_PERSON;
  payload: { data: RequestBody<"/customers/addPerson"> };
}

export interface IUpdateCustomerPersonAction extends UnknownAction {
  type: CUSTOMER_PERSONS_ACTION_TYPES.UPDATE_CUSTOMER_PERSON;
  payload: { updateData: RequestBody<"/customers/updatePerson">; id: number };
}

export interface IDeleteCustomerPersonAction extends UnknownAction {
  type: CUSTOMER_PERSONS_ACTION_TYPES.DELETE_CUSTOMER_PERSON;
  payload: { ids: number[] };
}

export interface ISetTableHiddenColumns extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { hiddenColumns: Record<string, boolean> };
}

export interface IResetState extends UnknownAction {
  type: CUSTOMER_PERSONS_ACTION_TYPES.RESET_STATE;
}

export interface IChangeColumnsOrder extends Action {
  type: CUSTOMER_PERSONS_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export type CustomersAction =
  | IGetInitialCustomerPersonsRequestAction
  | IGetInitialCustomerPersonsSuccessAction
  | IGetInitialCustomerPersonsErrorAction
  | IGetCustomerPersonsRequestAction
  | IGetCustomerPersonsSuccessAction
  | ICreateCustomerPersonAction
  | IUpdateCustomerPersonAction
  | IDeleteCustomerPersonAction
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | ISetTableHiddenColumns
  | IResetState
  | IChangeColumnsOrder;
