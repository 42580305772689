import { FaqsResponseBody } from "@ero/app-common/v2/routes/models/faq";
import { type Action, type UnknownAction } from "redux";

export enum HELP_ACTION_TYPES {
  GET_FAQS_REQUEST = "help/get-faqs-request",
  GET_FAQS_SUCCESS = "help/get-faqs-success",
  GET_FAQS_ERROR = "help/faqs",
}

export interface IGetFaqsRequest extends UnknownAction {
  type: HELP_ACTION_TYPES.GET_FAQS_REQUEST;
}

export interface IGetFaqsSuccess extends Action {
  type: HELP_ACTION_TYPES.GET_FAQS_SUCCESS;
  payload: { faqs: FaqsResponseBody["data"] };
}

export interface IGetFaqsError extends Action {
  type: HELP_ACTION_TYPES.GET_FAQS_ERROR;
}

export type HelpAction = IGetFaqsRequest | IGetFaqsSuccess | IGetFaqsError;
