export const VALIDATION_REGEX = {
  userName: /^\S+$/,
  phone: /^(\+?(\d[\d\s-()]*?){6,})?$/,
  durationWithSeconds: /^\d{1,2}:\d{1,2}:\d{1,2}$/,
  password: /(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/,
};

export const MAX_LENGTH = {
  DEFAULT: 100,
  NUMBER: 1000000,
  LONG_STRING: 255,
};
