import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { getOrdersV2 } from "Api";
import { MAX_LENGTH } from "Constants";
import { useDateRestrictionsSchema } from "Hooks";
import { useValidationErrors } from "Hooks/validationErrors";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

export type ValuesType = {
  name: string;
  dateRestrictions: {
    dateMode: DateMode | undefined;
    timeMode: DateTimeMode | undefined;
    date: number | undefined;
    startDate: number | undefined;
    endDate: number | undefined;
    dayTime: {
      startTime: number | undefined;
      endTime: number | undefined;
    };
  };
  customer: string;
  jobs: ({ parcel: number; service: number; create: boolean } | undefined)[];
  notes?: string;
};

export const initialValues: ValuesType = {
  name: "",
  customer: "",
  dateRestrictions: {
    dateMode: DateMode.FIXEDDATE,
    timeMode: DateTimeMode.WITHOUTTIME,
    date: undefined,
    startDate: undefined,
    endDate: undefined,
    dayTime: {
      startTime: undefined,
      endTime: undefined,
    },
  },
  jobs: [],
  notes: undefined,
};

export const useValidationSchema = (jobsRequired = false) => {
  const [t] = useTranslation();

  const validationErrors = useValidationErrors();

  const dateRestrictionsSchema = useDateRestrictionsSchema();

  const jobsSchema = useMemo(() => {
    let schema = Yup.array(
      Yup.object({
        parcel: Yup.number().required(),
        service: Yup.number().required(),
        create: Yup.bool().optional(),
      })
        .default(null)
        .nullable(),
    );
    if (jobsRequired) {
      schema = schema.test(
        "create-at-least-one-job",
        t("notification.jobsRequired"),
        (jobs) => jobs?.some((job) => job?.create) ?? false,
      );
    } else {
      schema = schema.optional();
    }
    return schema;
  }, [jobsRequired, t]);

  const cacheValidation = useCallback(
    (asyncValidate: (val: string) => Promise<boolean>) => {
      let isValid = false;
      let prevValue = "";

      return async (value: string) => {
        if (value !== prevValue) {
          const response = await asyncValidate(value);
          prevValue = value;
          isValid = response;
          return response;
        }
        return isValid;
      };
    },
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkOrderNameUniqueness = useCallback(
    cacheValidation(async (search: string) => {
      const result = await getOrdersV2({ search: `^${search}$`, limit: 1 });
      return result.maxCount === 0;
    }),
    [cacheValidation, getOrdersV2],
  );

  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded)
          .required(validationErrors.required)
          .test(
            "is-unique",
            t("notification.orderNameNotUnique"),
            checkOrderNameUniqueness,
          ),
        customer: Yup.number().positive().required(validationErrors.required),
        jobs: jobsSchema,
        dateRestrictions: dateRestrictionsSchema,
        notes: Yup.string().optional(),
      }),
    [
      validationErrors.maxLengthExceeded,
      validationErrors.required,
      t,
      checkOrderNameUniqueness,
      jobsSchema,
      dateRestrictionsSchema,
    ],
  );
  return schema;
};
