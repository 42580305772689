import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/jobs";
import {
  AddJobRequestBody,
  JobResponseBody,
  JobsRequestQuery,
  JobTelemetryResponseBody,
  UpdateJobRequestBody,
  type JobTelemetryUpdateRequest,
} from "@ero/app-common/v2/routes/models/job";
import { apiInstance, apiInstanceV2 } from "App/axios";
import { RequestBody } from "Types";

export const getJobsV2 = async (params: JobsRequestQuery) =>
  (await apiInstanceV2.get(baseUrl, { params })).data;

export const createJobV2 = (data: AddJobRequestBody) =>
  apiInstanceV2.post(baseUrl + SubUrls.ROOT, data);

export const updateJobV2 = (id: number, body: UpdateJobRequestBody) =>
  apiInstanceV2.patch<JobResponseBody>(
    baseUrl + SubUrls.BY_ID.replace(":id", `${id}`),
    body,
  );

export const deleteJobV2 = (id: number) =>
  apiInstanceV2.delete(baseUrl + SubUrls.BY_ID.replace(":id", id.toString()));

export const updateJob = (updateData: RequestBody<"/jobs/update">) =>
  apiInstance.post("/jobs/update", updateData);

export const rescheduleJobs = (data: RequestBody<"/jobs/bulkReschedule">) =>
  apiInstance.post("/jobs/bulkReschedule", { edits: data });

export const assignJobEmployees = (
  job: number,
  body: RequestBody<"/jobs/assignEmployees">,
) => apiInstance.post(`/jobs/assignEmployees`, body, { params: { job } });

export const freeJobEmployees = (
  job: number,
  body: RequestBody<"/jobs/freeEmployees">,
) => apiInstance.post(`/jobs/freeEmployees`, body, { params: { job } });

export const getJobTelemetryV2 = (id: number, omitTrack?: boolean) =>
  apiInstanceV2.get<JobTelemetryResponseBody>(
    baseUrl + SubUrls.TELEMETRY.replace(":id", `${id}`),
    {
      params: { omitTrack },
    },
  );

export const updateJobTelemetryV2 = (
  id: number,
  telemetry: JobTelemetryUpdateRequest,
) =>
  apiInstanceV2.patch<JobTelemetryResponseBody>(
    baseUrl + SubUrls.TELEMETRY.replace(":id", `${id}`),
    telemetry,
  );
