import { VALIDATION_REGEX } from "Constants";
import { useValidationErrors } from "Hooks/validationErrors";
import { type RESET_PASS_DTO } from "Types";
import { useMemo } from "react";
import * as Yup from "yup";

export const initialValues: RESET_PASS_DTO = {
  password: "",
  repeat: "",
};

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();

  const schema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .matches(VALIDATION_REGEX.password, {
            message: validationErrors.unsafePassword,
          })
          .required(validationErrors.required),
        repeat: Yup.string()
          .oneOf(
            [Yup.ref("password"), ""],
            validationErrors.passwordsDoNotMatch,
          )
          .required(validationErrors.required),
      }),
    [
      validationErrors.passwordsDoNotMatch,
      validationErrors.unsafePassword,
      validationErrors.required,
    ],
  );

  return schema;
};
