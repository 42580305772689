import { MACHINEKIND } from "@ero/app-common/enums/MACHINEKIND";
import Container from "@mui/material/Container/Container";
import Grid2 from "@mui/material/Grid2/Grid2";
import { AvatarUpload, Input } from "Components";
import { SelectWithAutoComplete } from "Components/FormikAutocomplete/SelectWithAutoComplete";
import { StaticMultiSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticMultiSelectWithAutoComplete";
import { StaticSelectWithAutoComplete } from "Components/FormikAutocomplete/StaticSelectWithAutoComplete";
import { useDropdownValues } from "Hooks/dropdownValues";

import MachineSvg from "Assets/icons/machine.svg?react";
import { DateInput } from "Components/FormikDateInput/DateInput";
import { ValuesType } from "Components/machineInformationModal/formConfig";
import { useMachineDropdownOptions } from "Utils/machines";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const FormContent: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<ValuesType>();
  const [t] = useTranslation();

  const { kindOptions, alignmentOptions, statusOptions } =
    useMachineDropdownOptions();

  const { fetchDropdownValues: fetchManufacturerOptions } =
    useDropdownValues("manufacturer");

  const handleAvatarUpload = useCallback(
    (image: string) => {
      setFieldValue("avatar", image);
    },
    [setFieldValue],
  );

  return (
    <Container maxWidth={"xl"}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <AvatarUpload
            name="machine"
            alt={values.name ?? "M"}
            src={values.avatar}
            onSubmit={handleAvatarUpload}
          >
            <MachineSvg style={{ strokeWidth: "1px", width: 60, height: 60 }} />
          </AvatarUpload>
        </Grid2>
        <Grid2 size={4}>
          <Grid2 container spacing={3}>
            <Grid2 size={12}>
              <Input
                name="name"
                type="text"
                label={t("machines.fields.name")}
                mandatory={true}
              />
            </Grid2>
            <Grid2 size={12}>
              <StaticSelectWithAutoComplete
                name="kind"
                required={true}
                label={t("machines.fields.kind")}
                disableClearable
                options={kindOptions}
              />
            </Grid2>
            <Grid2 size={12}>
              <SelectWithAutoComplete
                name="manufacturer"
                label={t("machines.fields.manufacturer")}
                disableClearable
                fetchOptions={fetchManufacturerOptions}
              />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="model"
                type="text"
                label={t("machines.fields.model")}
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="serialNumber"
                type="text"
                label={t("machines.fields.serialNumber")}
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="licensePlate"
                type="text"
                label={t("machines.fields.licensePlate")}
              />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="features"
                type="text"
                label={t("machines.fields.features")}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={4}>
          <Grid2 container spacing={3}>
            <Grid2 size={6}>
              <DateInput
                name="nextMaintenance"
                label={t("machines.fields.nextMaintenance")}
              />
            </Grid2>
            <Grid2 size={6}>
              <DateInput name="tuv" label={t("machines.fields.date")} />
            </Grid2>
            <Grid2 size={12}>
              <Input
                name="maintenanceInfo"
                type="text"
                label={t("machines.fields.maintenanceInfo")}
              />
            </Grid2>
            <Grid2 size={6}>
              <StaticMultiSelectWithAutoComplete
                name="alignment"
                options={alignmentOptions}
                label={t("machines.fields.alignment")}
                disabled={
                  values.kind !== MACHINEKIND.ATTACHMENT &&
                  values.kind !== MACHINEKIND.TRACTOR &&
                  values.kind !== MACHINEKIND.OTHER
                }
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="loadingCapacity"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.loadingCapacity")}
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="length"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.length")}
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="height"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.height")}
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="width"
                disabled={values.kind === MACHINEKIND.HANDHELD}
                type="number"
                label={t("machines.fields.width")}
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="workingWidth"
                type="number"
                label={t("machines.fields.workingWidth")}
              />
            </Grid2>
            <Grid2 size={6}>
              <Input
                name="performance"
                type="number"
                disabled={values.kind === 3}
                label={t("machines.fields.performance")}
              />
            </Grid2>
            <Grid2 size={6}>
              <StaticSelectWithAutoComplete
                name="intact"
                label={t("machines.fields.status")}
                disableClearable
                options={statusOptions}
                required={true}
              />
            </Grid2>
          </Grid2>
        </Grid2>
        <Grid2 size={4}>
          <Input
            name="notes"
            type="text"
            multiline={true}
            rows={20}
            label={t("machines.fields.notes")}
          />
        </Grid2>
      </Grid2>
    </Container>
  );
};
