import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Logo from "Assets/Logo_EROplan_RGB.png";
import LogoNegativ from "Assets/Logo_EROplan_negativ_RGB.png";
import { DarkModeSwitch, LangChooseSwitch } from "Components";
import { AppState } from "Store";
import { forgotPassword, resetMeta } from "Store/auth";
import { FORGOT_PASS_DTO } from "Types";
import { Formik } from "formik";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { initialValues, useValidationSchema } from "./formConfig";
import { FormContent } from "./formContent";

export const ForgotPassword: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useValidationSchema();

  const { colorMode } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    return (): void => {
      dispatch(resetMeta());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback((values: FORGOT_PASS_DTO) => {
    dispatch(forgotPassword(values.userName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <img
        src={colorMode === "light" ? Logo : LogoNegativ}
        alt="logo"
        style={{ maxWidth: "100%", padding: 32 }}
      />
      <Box sx={{ paddingX: 4 }}>
        <Stack direction="row" justifyContent="center" spacing={2} pb={2}>
          <LangChooseSwitch />
          <DarkModeSwitch />
        </Stack>
        <Typography textAlign="center" variant="h4" pb={2}>
          {t("auth.forgotPassword.link")}
        </Typography>
        <Typography variant="body1" pb={2}>
          {t("auth.forgotPassword.usernameHint")}
        </Typography>
        <Typography variant="body1" pb={2}>
          {t("auth.forgotPassword.mailHint")}
        </Typography>
      </Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        <FormContent />
      </Formik>
    </>
  );
};
