import { CloudDownload } from "@mui/icons-material";
import { Box, Button, Grid2, Stack, Typography } from "@mui/material";
import { AddJobModal } from "Components";
import { type AppState } from "Store";
import { sagaActions } from "Store/orderDetails/orderDetailsSagaActions";
import { getOrderStatusColor, getOrderStatusLabel } from "Utils";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MapCard, OverviewCard } from "./components";
import { ContactCard } from "./components/contactCard/contactCard";
import { JobsCard } from "./components/jobsCard";
import { NotesCard } from "./components/notesCard/notesCard";

export const OrderDetails: React.FC = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();

  const { order } = useSelector((state: AppState) => state.orderDetails);

  const params = useParams<{ id: string }>();
  const orderId = useMemo(() => parseInt(params.id || ""), [params.id]);

  const [isAddJobModalOpen, setIsAddJobModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(sagaActions.getOrder(orderId));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const onDeliveryNoteClick = () => {
    dispatch(sagaActions.downloadDeliveryNote());
  };

  const orderTitle = useMemo(() => {
    return (
      <Typography variant="h5">
        {`${order?.name} | `}
        <Box component="span" color={getOrderStatusColor(order?.status)}>
          {getOrderStatusLabel(order?.status)}
        </Box>
      </Typography>
    );
  }, [order?.name, order?.status]);

  if (!order) {
    return <div>No such order</div>;
  }

  return (
    <>
      <Grid2 container padding={3} height="100%">
        <Grid2
          height="100%"
          size={{
            xs: 12,
            sm: 5,
            md: 4,
            lg: 3,
            xl: 2,
          }}
        >
          <JobsCard onAddBtnClick={() => setIsAddJobModalOpen(true)} />
        </Grid2>
        <Grid2
          pl={2}
          size={{
            xs: 12,
            sm: 7,
            md: 8,
            lg: 9,
            xl: 10,
          }}
        >
          <Stack direction={"column"} height="100%">
            <Grid2 container justifyContent={"space-between"} mb={2}>
              <Grid2>{orderTitle}</Grid2>
              <Grid2>
                <Button
                  variant="outlined"
                  onClick={onDeliveryNoteClick}
                  startIcon={<CloudDownload />}
                >
                  {t("orders.details.deliveryNote.title")}
                </Button>
              </Grid2>
            </Grid2>
            <Grid2 container spacing={2} alignItems={"stretch"}>
              <Grid2
                height="100%"
                size={{
                  xs: 12,
                  lg: 6,
                }}
              >
                <OverviewCard />
              </Grid2>
              <Grid2
                size={{
                  xs: 12,
                  sm: 6,
                  lg: 3,
                }}
              >
                <ContactCard />
              </Grid2>
              <Grid2
                size={{
                  xs: 12,
                  sm: 6,
                  lg: 3,
                }}
              >
                <NotesCard />
              </Grid2>
            </Grid2>
            <Box height="100%" pt={2}>
              <MapCard />
            </Box>
          </Stack>
        </Grid2>
      </Grid2>
      <AddJobModal
        open={isAddJobModalOpen}
        onClose={() => {
          setIsAddJobModalOpen(false);
        }}
      />
    </>
  );
};
