import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from "@mui/material";
import { PasswordInput } from "Components/controls/PasswordInput";
import { AppState } from "Store";
import { changePassword } from "Store/auth";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ValuesType, initialValues, useValidationSchema } from "./formConfig";

interface IChangePasswordDialog {
  open: boolean;
  onClose: () => void;
}

export const ChangePasswordDialog: React.FC<IChangePasswordDialog> = ({
  open,
  onClose,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useValidationSchema();

  const { loading } = useSelector((store: AppState) => store.auth);

  const onSubmit = useCallback(
    (values: ValuesType) => {
      dispatch(
        changePassword({
          currentPassword: values.oldPassword,
          newPassword: values.newPassword,
        }),
      );
      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onClose],
  );

  return (
    <Dialog open={open} onClose={onClose} scroll="body">
      <DialogTitle>{t("changePassword.title")}</DialogTitle>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ resetForm, dirty, isValid }) => (
          <Form>
            <DialogContent dividers>
              <Grid2 container spacing={3}>
                <Grid2 size={12}>
                  <PasswordInput
                    name="oldPassword"
                    label={t("changePassword.oldPassword")}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <PasswordInput
                    name="newPassword"
                    label={t("changePassword.newPassword")}
                  />
                </Grid2>
                <Grid2 size={12}>
                  <PasswordInput
                    name="confirmPassword"
                    label={t("changePassword.confirmPassword")}
                  />
                </Grid2>
              </Grid2>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  resetForm();
                }}
              >
                {t("general.buttons.cancel")}
              </Button>
              <LoadingButton
                loading={loading}
                type="submit"
                disabled={!isValid || !dirty}
              >
                {t("general.buttons.save")}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
