import { LANGUAGE } from "@ero/app-common/enums/language";
import EditIcon from "@mui/icons-material/Edit";
import { Grid2, IconButton, Stack, TextField, Typography } from "@mui/material";
import { OneFieldForm } from "Components/oneFieldForm";
import { OneFieldFormSelect } from "Components/oneFieldForm/oneFieldFormSelect";
import { type AppState } from "Store";
import { changeLanguage, updateUserData } from "Store/auth";
import { OptionType } from "Types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordDialog } from "../changePasswordModal/changePasswordDialog";
import { useValidationSchemata } from "./validationConfig";

const languageOptions: OptionType[] = [
  {
    value: LANGUAGE.EN,
    label: "English",
  },
  {
    value: LANGUAGE.DE,
    label: "Deutsch",
  },
];

export const UserInformation: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const validationSchemata = useValidationSchemata();

  const { userData, success } = useSelector((state: AppState) => state.auth);

  const [changePasswordOpen, toggleChangePassword] = useState(false);

  const onSubmit = useCallback((values: Record<string, string>) => {
    dispatch(updateUserData(values));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguageHandler = useCallback((lang: LANGUAGE) => {
    dispatch(changeLanguage(lang));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid2 container spacing={4} maxWidth={"lg"}>
      <Grid2 size={12}>
        <Typography variant="h4">
          {t("profile.userInformation.title")}
        </Typography>
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="firstName"
          value={userData.firstName}
          onSubmit={onSubmit}
          validationRules={validationSchemata.freeField}
          label={t("general.fields.firstName")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="lastName"
          value={userData.lastName}
          onSubmit={onSubmit}
          validationRules={validationSchemata.freeField}
          label={t("general.fields.lastName")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="email"
          value={userData.email}
          onSubmit={onSubmit}
          validationRules={validationSchemata.email}
          label={t("profile.userInformation.mail")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <OneFieldForm
          name="phone"
          value={userData.phone}
          onSubmit={onSubmit}
          validationRules={validationSchemata.phone}
          label={t("profile.userInformation.phone")}
          success={success}
        />
      </Grid2>
      <Grid2 size={6}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            value="**************"
            disabled
            label={t("profile.userInformation.password")}
            variant="outlined"
            fullWidth
          />
          <IconButton
            onClick={() => {
              toggleChangePassword(true);
            }}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      </Grid2>
      <Grid2 size={6}>
        <OneFieldFormSelect
          name="language"
          label="Sprache"
          success={success}
          onSubmit={(values) => {
            changeLanguageHandler(values.language);
          }}
          value={userData.language}
          options={languageOptions}
        />
      </Grid2>
      <ChangePasswordDialog
        open={changePasswordOpen}
        onClose={() => toggleChangePassword(false)}
      />
    </Grid2>
  );
};
