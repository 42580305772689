import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import { type FORGOT_PASS_DTO } from "Types";
import * as Yup from "yup";

export const initialValues: FORGOT_PASS_DTO = {
  userName: "",
};

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();

  const schema = useMemo(
    () =>
      Yup.object().shape({
        userName: Yup.string().required(validationErrors.required),
      }),
    [validationErrors.required],
  );

  return schema;
};
