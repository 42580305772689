import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import * as Yup from "yup";

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        parcel: Yup.number().required(validationErrors.required),
        service: Yup.number().required(validationErrors.required),
        notes: Yup.string().optional(),
      }),
    [validationErrors.required],
  );
  return schema;
};
