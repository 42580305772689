import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import { file2base64 } from "Utils";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { getCroppedImg, type AreaPixels, type Point } from "./helpers";

const minZoom = 1,
  maxZoom = 3,
  step = 0.1;

interface IAvatarCropModal {
  open: boolean;
  onClose: () => void;
  selectedImage: File | undefined;
  onSubmit: (value: string) => void;
}

export const AvatarCropModal: React.FC<IAvatarCropModal> = ({
  open,
  onClose,
  selectedImage,
  onSubmit,
}) => {
  const [base64Image, setBase64Image] = useState("");
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(minZoom);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<AreaPixels>();

  const [t] = useTranslation();

  useEffect(() => {
    if (selectedImage) {
      file2base64(selectedImage).then((image: string) => setBase64Image(image));
    }
  }, [selectedImage]);

  const onCloseHandler = useCallback(() => {
    setBase64Image("");
    setCrop({ x: 0, y: 0 });
    setZoom(minZoom);
    setCroppedAreaPixels(undefined);
    onClose();
  }, [onClose]);

  const onCropChange = useCallback((crop: Point) => {
    setCrop(crop);
  }, []);

  const onCropComplete = useCallback((_, newCroppedAreaPixels: AreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const onZoomChange = useCallback((zoom: number) => {
    setZoom(zoom);
  }, []);

  const increaseZoom = useCallback(() => {
    if (zoom < maxZoom) {
      setZoom(zoom + step);
    }
  }, [zoom]);

  const decreaseZoom = useCallback(() => {
    if (zoom > minZoom) {
      setZoom(zoom - step);
    }
  }, [zoom]);

  const onSubmitHandler = useCallback(async () => {
    try {
      if (!croppedAreaPixels) return;

      const croppedImage = await getCroppedImg(base64Image, croppedAreaPixels);

      onSubmit(croppedImage);
      onCloseHandler();
    } catch (error) {
      console.log(error);
    }
  }, [base64Image, croppedAreaPixels, onCloseHandler, onSubmit]);

  return (
    <Dialog open={open} onClose={onCloseHandler}>
      <DialogTitle>{t("general.labels.avatarUploadTitle")}</DialogTitle>
      <DialogContent dividers>
        <Cropper
          style={{
            cropAreaStyle: { width: 500 },
            containerStyle: { width: 500, height: 600, position: "relative" },
          }}
          image={base64Image}
          maxZoom={maxZoom}
          crop={crop}
          zoom={zoom}
          aspect={1}
          cropShape="round"
          showGrid={true}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={4}
          sx={{ width: 400 }}
          alignItems="center"
        >
          <IconButton onClick={decreaseZoom}>
            <RemoveOutlined />
          </IconButton>
          <Slider
            value={zoom}
            min={minZoom}
            max={maxZoom}
            step={step}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => onZoomChange(zoom as number)}
          />
          <IconButton onClick={increaseZoom}>
            <AddOutlined />
          </IconButton>
          <Button onClick={onSubmitHandler} fullWidth>
            {t("general.labels.avatarUploadButton")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
