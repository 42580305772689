import { MACHINEALIGNMENT } from "@ero/app-common/enums";
import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { MAX_LENGTH } from "Constants";
import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import * as Yup from "yup";

export type ValuesType = {
  _id?: number;
  internalId?: number;

  name?: string;
  kind?: number | string;
  manufacturer?: number | string;
  model?: string;
  serialNumber?: string;
  licensePlate?: string;
  features?: string;
  nextMaintenance?: Milliseconds | string;
  tuv?: Milliseconds | string;
  maintenanceInformation?: string;
  alignment?: MACHINEALIGNMENT[];
  loadingCapacity?: number | string;
  length?: number | string;
  height?: number | string;
  width?: number | string;
  workingWidth?: number | string;
  performance?: number | string;
  intact?: number | string;
  notes?: string;
  avatar?: string;
};

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded)
          .required(validationErrors.required)
          .min(1),
        kind: Yup.number().required(validationErrors.required),
        manufacturer: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        model: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        serialNumber: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        licensePlate: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        features: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        performance: Yup.number()
          .min(0, validationErrors.belowMinValue)
          .max(MAX_LENGTH.NUMBER, validationErrors.maxValueExceeded),
        width: Yup.number()
          .min(0, validationErrors.belowMinValue)
          .max(MAX_LENGTH.NUMBER, validationErrors.maxValueExceeded),
        length: Yup.number()
          .min(0, validationErrors.belowMinValue)
          .max(MAX_LENGTH.NUMBER, validationErrors.maxValueExceeded),
        height: Yup.number()
          .min(0, validationErrors.belowMinValue)
          .max(MAX_LENGTH.NUMBER, validationErrors.maxValueExceeded),
        workingWidth: Yup.number()
          .min(0, validationErrors.belowMinValue)
          .max(MAX_LENGTH.NUMBER, validationErrors.maxValueExceeded),
        nextMaintenance: Yup.number().transform((value) =>
          isNaN(value) ? undefined : value,
        ),
        tuv: Yup.number().transform((value) =>
          isNaN(value) ? undefined : value,
        ),
        intact: Yup.number().min(0).max(1).required(validationErrors.required),
        alignment: Yup.array(Yup.number()),
        loadingCapacity: Yup.number()
          .max(MAX_LENGTH.NUMBER, validationErrors.maxValueExceeded)
          .min(0, validationErrors.belowMinValue),
      }),
    [
      validationErrors.maxLengthExceeded,
      validationErrors.maxValueExceeded,
      validationErrors.belowMinValue,
      validationErrors.required,
    ],
  );
  return schema;
};
