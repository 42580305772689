import { PERM } from "@ero/app-common/enums";
import { LANGUAGE } from "@ero/app-common/enums/language";
import { MAX_LENGTH, VALIDATION_REGEX } from "Constants";
import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import * as Yup from "yup";

export const useValidationSchema = (editMode: boolean) => {
  const validationErrors = useValidationErrors();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        userName: Yup.string()
          .required(validationErrors.required)
          .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded)
          .matches(VALIDATION_REGEX.userName, validationErrors.username),
        email: Yup.string()
          .matches(VALIDATION_REGEX.email, validationErrors.invalidEmailAddress)
          .when(
            "manualPasswordSelection",
            (manualPasswordSelection, schema) => {
              return !manualPasswordSelection[0] && !editMode
                ? schema.required(validationErrors.required)
                : schema.optional();
            },
          ),
        password: Yup.string()
          .matches(VALIDATION_REGEX.password, {
            message: validationErrors.unsafePassword,
          })
          .when(
            "manualPasswordSelection",
            (manualPasswordSelection, schema) => {
              return manualPasswordSelection[0] && !editMode
                ? schema.required(validationErrors.required)
                : schema.optional();
            },
          ),
        permission: Yup.number().oneOf([PERM.ORGANIZER, PERM.DRIVER]),
        language: Yup.string().oneOf([LANGUAGE.DE, LANGUAGE.EN]),
        firstName: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        lastName: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        phone: Yup.string().matches(
          VALIDATION_REGEX.phone,
          validationErrors.phone,
        ),
        notes: Yup.string().max(
          MAX_LENGTH.LONG_STRING,
          validationErrors.maxLengthExceeded,
        ),
      }),
    [
      editMode,
      validationErrors.maxLengthExceeded,
      validationErrors.unsafePassword,
      validationErrors.phone,
      validationErrors.required,
      validationErrors.username,
      validationErrors.invalidEmailAddress,
    ],
  );
  return schema;
};
