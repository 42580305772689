import { DateMode, DateTimeMode } from "@ero/app-common/enums/DateTimeMode";
import { OrderDateType } from "@ero/app-common/models";
import dayjs from "dayjs";

export function displayTime(timestamp: number): string {
  const timeFormat = "HH:mm";

  if (timestamp === -1) {
    return "";
  }
  return dayjs(timestamp).format(timeFormat);
}

export function displayDate(
  timestamp: number,
  useTime: boolean,
  hideYear: boolean = false,
): string {
  let dateFormat: string;

  if (timestamp === -1 || timestamp === 0 || timestamp === undefined) {
    return "";
  }

  if (hideYear) {
    dateFormat = useTime ? "DD.MM. HH:mm" : "DD.MM.";
  } else {
    dateFormat = useTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";
  }

  return dayjs(timestamp).format(dateFormat);
}

export const displayDateRestriction = (
  dateRestrictions: OrderDateType | undefined,
  todoLabel: string,
  untilLabel: string,
): string => {
  let content: string = "";
  if (dateRestrictions !== undefined && typeof dateRestrictions === "object") {
    const useTime = dateRestrictions.timeMode === DateTimeMode.WITHTIME;

    switch (dateRestrictions.dateMode) {
      case DateMode.FIXEDDATE:
        content = displayDate(dateRestrictions.date!, useTime);
        break;
      case DateMode.DATERANGE:
        if (
          dateRestrictions.endDate !== undefined &&
          dateRestrictions.endDate !== 0
        ) {
          content =
            displayDate(
              dateRestrictions.startDate!,
              useTime,
              new Date(dateRestrictions.startDate || 0).getFullYear() ===
                new Date(dateRestrictions.endDate).getFullYear(),
            ) +
            `${
              dateRestrictions.startDate == undefined ||
              dateRestrictions.startDate == 0
                ? todoLabel
                : ""
            }  ${untilLabel} ` +
            displayDate(dateRestrictions.endDate!, useTime);
        }
        break;
      default:
        content = "";
        break;
    }

    if (dateRestrictions.timeMode == DateTimeMode.WITHDAYTIME) {
      const startTime =
        dateRestrictions.dayTime?.startTime &&
        displayTime(dateRestrictions.dayTime?.startTime);

      const endTime =
        dateRestrictions.dayTime?.endTime &&
        displayTime(dateRestrictions.dayTime?.endTime);

      content += `${", " + startTime + "  " + untilLabel + "  " + endTime}`;
    }
  }
  return content;
};
