import { MessageType, PERM } from "@ero/app-common/enums";
import {
  type SubscribeMessageDrivers,
  type SubscribeMessageMachines,
  type UnsubscribeMessage,
} from "@ero/app-common/util/Websocket";
import { getEmployeesV2, getMachinesV2, getParcels } from "Api";
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { errorToast } from "Services";

import { changeWsSubscription } from "../general";

import { ParcelsRequestQuery } from "@ero/app-common/v2/routes/models/parcel";
import dayjs from "dayjs";
import {
  DASHBOARD_ACTION_TYPES,
  ISetParcelFilters,
  type ISetSelectionType,
} from "./action-types";
import {
  setDrivers,
  setError,
  setLoading,
  setMachines,
  setParcels,
  setSelectionTypeSuccess,
  setSuccess,
} from "./actions";
import { ParcelFilters } from "./reducer";

export function* downloadMachinesSaga() {
  try {
    const response = yield call(getMachinesV2, { limit: 9999999999 });

    yield put(setMachines({ list: response.data }));

    return response.data;
  } catch (error) {
    errorToast(undefined, undefined, error);
  }
}

export function* downloadParcelsSaga(filters?: ParcelFilters) {
  try {
    const query: ParcelsRequestQuery = {
      limit: 9999999999,
      includeJobs: true,
    };
    if (filters) {
      if (filters.showUnplanned) {
        query.orderStartFrom = filters?.timeRange.start;
        query.orderStartTo = filters?.timeRange.end;
      } else {
        query.jobStartFrom = filters?.timeRange.start;
        query.jobStartTo = filters?.timeRange.end;
      }
    }
    const { data } = yield call(getParcels, query);

    yield put(setParcels({ list: data }));

    return data.data;
  } catch (error) {
    yield put(setError());
    errorToast(undefined, undefined, error);
  }
}

export function* getMachinesSaga() {
  const machinesUnsubscribeMessage: UnsubscribeMessage = {
    t: MessageType.UNSUBSCRIBE,
    payload: "machines",
  };

  yield put(changeWsSubscription(machinesUnsubscribeMessage));

  yield put(setLoading());

  const machinesList = yield downloadMachinesSaga();

  const machinesSubscribeMessage: SubscribeMessageMachines = {
    t: MessageType.SUBSCRIBE,
    payload: "machines",
    meta: machinesList.map((item) => item._id),
  };

  yield put(changeWsSubscription(machinesSubscribeMessage));

  yield put(setSuccess());
}

export function* getParcelsSaga({ payload: filters }: ISetParcelFilters) {
  yield put(setLoading());

  yield downloadParcelsSaga(filters);

  yield put(setSuccess());
}

export function* dashboardCancelUpdateSaga() {
  const machinesUnsubscribeMessage: UnsubscribeMessage = {
    t: MessageType.UNSUBSCRIBE,
    payload: "machines",
  };

  yield put(changeWsSubscription(machinesUnsubscribeMessage));

  const driverUnsubscribeMessage: UnsubscribeMessage = {
    t: MessageType.UNSUBSCRIBE,
    payload: "drivers",
  };

  yield put(changeWsSubscription(driverUnsubscribeMessage));
}

export function* machinesInitialSaga() {
  try {
    const machinesUnsubscribeMessage: UnsubscribeMessage = {
      t: MessageType.UNSUBSCRIBE,
      payload: "machines",
    };

    yield put(changeWsSubscription(machinesUnsubscribeMessage));

    yield downloadMachinesSaga();

    const machinesSubscribeMessage: SubscribeMessageMachines = {
      t: MessageType.SUBSCRIBE,
      payload: "machines",
    };

    yield put(changeWsSubscription(machinesSubscribeMessage));
  } catch (error) {
    errorToast(undefined, undefined, error);
  }
}

export function* parcelsInitialSaga() {
  const store = yield select();
  const filter = store.dashboard.parcelFilters;

  yield downloadParcelsSaga(filter);
}

export function* driversInitialSaga() {
  yield put(setLoading());

  const driversUnsubscribeMessage: UnsubscribeMessage = {
    t: MessageType.UNSUBSCRIBE,
    payload: "drivers",
  };
  yield put(changeWsSubscription(driversUnsubscribeMessage));

  const store = yield select();
  const trackDate: { start: number; end: number } | undefined =
    store.dashboard.parcelFilters?.timeRange;

  const data = yield call(getEmployeesV2, {
    type: PERM.DRIVER,
    includeTrack: true,
    ...(trackDate
      ? { trackDateStart: trackDate.start, trackDateEnd: trackDate.end }
      : {}),
  });

  yield put(setDrivers(data));

  const filterShowsPast =
    dayjs(trackDate?.start) < dayjs(Date.now()).startOf("day");
  const filterShowsFuture =
    dayjs(trackDate?.start) > dayjs(Date.now()).endOf("day");

  const filterShowsToday = !filterShowsPast && !filterShowsFuture;
  if (filterShowsToday) {
    const driversSubscribeMessage: SubscribeMessageDrivers = {
      t: MessageType.SUBSCRIBE,
      payload: "drivers",
    };
    yield put(changeWsSubscription(driversSubscribeMessage));
  }

  yield put(setSuccess());
}

export function* removeDriversSubscriptionSaga() {
  const driversUnsubscribeMessage: UnsubscribeMessage = {
    t: MessageType.UNSUBSCRIBE,
    payload: "drivers",
  };

  yield put(changeWsSubscription(driversUnsubscribeMessage));
  yield put(setDrivers({ data: [] }));
}

export function* dashboardInitialSaga() {
  const store = yield select();
  const showDriversTrack = store.dashboard.showDriversTrack;

  yield put(setLoading());

  yield machinesInitialSaga();
  yield parcelsInitialSaga();
  if (showDriversTrack) yield fork(driversInitialSaga);

  yield put(setSuccess());
}

export function* setSelectionType({ payload }: ISetSelectionType) {
  const { type } = payload;
  yield put(setSelectionTypeSuccess(type));
}

export default function* dashboardSaga() {
  yield all([
    takeEvery(DASHBOARD_ACTION_TYPES.SET_SELECTION_TYPE, setSelectionType),
    takeEvery(
      DASHBOARD_ACTION_TYPES.DASHBOARD_INITIAL_REQUEST,
      dashboardInitialSaga,
    ),
    takeEvery(
      DASHBOARD_ACTION_TYPES.DASHBOARD_CANCEL_UPDATE,
      dashboardCancelUpdateSaga,
    ),
    takeEvery(DASHBOARD_ACTION_TYPES.GET_DRIVERS, driversInitialSaga),
    takeEvery(
      DASHBOARD_ACTION_TYPES.REMOVE_DRIVERS_SUBSCRIPTION,
      removeDriversSubscriptionSaga,
    ),
    takeLatest(DASHBOARD_ACTION_TYPES.SET_PARCEL_FILTERS, getParcelsSaga),
    takeLatest(DASHBOARD_ACTION_TYPES.SET_PARCEL_FILTERS, driversInitialSaga),
  ]);
}
