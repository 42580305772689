import { OrderDateType } from "@ero/app-common/models";
import { OrderResponseBody } from "@ero/app-common/v2/routes/models/order";
import { Box, Typography } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { getOrderStatusColor, getOrderStatusLabel } from "Utils";
import { displayDateRestriction } from "Utils/date";
import i18n from "i18n/i18n";
import React from "react";
import { useTranslation } from "react-i18next";

const DisplayOrdersDate: React.FC<{
  dateRestrictions: OrderDateType;
}> = ({ dateRestrictions }) => {
  const [t] = useTranslation();
  const content = displayDateRestriction(
    dateRestrictions,
    t("orders.createModal.toDo"),
    t("orders.createModal.until"),
  );
  return <div>{content}</div>;
};

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();
  return [
    {
      field: "name",
      minWidth: 120,
      maxWidth: 200,
      headerName: t("orders.fields.id"),
    },
    {
      field: "status",
      minWidth: 100,
      maxWidth: 200,
      headerName: t("general.fields.status"),
      valueGetter: (value) => getOrderStatusLabel(value),
      renderCell: (params) => {
        const color = getOrderStatusColor(params.row.status);
        return (
          <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Typography variant="body2" color={color}>
              {params.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "customer",
      minWidth: 200,
      headerName: t("general.fields.customer"),
      valueGetter: (value: OrderResponseBody["customer"]) => value.companyName,
    },
    {
      field: "jobDetails",
      maxWidth: 120,
      headerName: t("general.fields.jobs"),
      valueGetter: (value: OrderResponseBody["jobDetails"]) =>
        value?.jobCount || 0,
    },
    {
      field: "dateRestrictions",
      renderCell: (params) => (
        <DisplayOrdersDate dateRestrictions={params.value} />
      ),
      minWidth: 200,
      headerName: t("orders.createModal.date"),
      valueFormatter: (value) =>
        displayDateRestriction(
          value,
          i18n.t("orders.createModal.toDo"),
          i18n.t("orders.createModal.until"),
        ),
    },
  ];
};
