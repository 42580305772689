import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type CREATE_RELEASENOTE_DTO, type GetTableDataParams } from "Types";
import { type Action, type UnknownAction } from "redux";
import { type LocalReleaseNoteType } from "./specs";

export enum RELEASENOTES_ACTION_TYPES {
  GET_INITIAL_RELEASENOTES_REQUEST = "releaseNotes/get-releaseNotes-initial-request",
  GET_INITIAL_RELEASENOTES_SUCCESS = "releaseNotes/get-releaseNotes-initial-success",
  GET_INITIAL_RELEASENOTES_ERROR = "releaseNotes/get-releaseNotes-error",
  GET_RELEASENOTES_REQUEST = "releaseNotes/get-releaseNotes-request",
  GET_RELEASENOTES_SUCCESS = "releaseNotes/get-releaseNotes-success",

  DELETE_RELEASENOTES = "releasenotes/delete-releasenotes",

  CREATE_RELEASENOTE = "releasenotes/create-releasenote",

  UPDATE_RELEASENOTE = "releasenotes/update-releasenote",

  SET_LOADING = "releaseNotes/set-loading",
  SET_SUCCESS = "releaseNotes/set-success",
  RESET_META = "releaseNotes/reset-meta",

  SET_ERROR = "releaseNotes/set-error",
  SET_TABLE_HIDDEN_COLUMNS = "releaseNotes/set-table-hidden-columns",
  CHANGE_COLUMNS_ORDER = "releaseNotes/change-columns-order",
}

export interface ISetLoadingAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.RESET_META;
}

export interface IGetInitialReleaseNotesRequestAction extends UnknownAction {
  type: RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_REQUEST;
  payload: {
    params: GetTableDataParams;
  };
}

export interface IGetInitialReleaseNotesSuccessAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_SUCCESS;
  payload: {
    releaseNotes: LocalReleaseNoteType[];
    maxCount: number;
  };
}

export interface IGetInitialReleaseNotesErrorAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.GET_INITIAL_RELEASENOTES_ERROR;
}

export interface IGetReleaseNotesRequestAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.GET_RELEASENOTES_REQUEST;
  payload: {
    params: GetTableDataParams;
    search?: SearchSpec;
  };
}

export interface IGetReleaseNotesSuccessAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.GET_RELEASENOTES_SUCCESS;
  payload: {
    releaseNotes: LocalReleaseNoteType[];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
  };
}

export interface IDeleteReleaseNotesAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.DELETE_RELEASENOTES;
  payload: { ids: number[] };
}

export interface ICreateReleaseNoteAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.CREATE_RELEASENOTE;
  payload: { data: CREATE_RELEASENOTE_DTO };
}

export interface ISetTableHiddenColumns extends Action {
  type: RELEASENOTES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { hiddenColumns: Record<string, boolean> };
}

export interface IChangeColumnsOrder extends Action {
  type: RELEASENOTES_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export interface IUpdateReleaseNotesAction extends Action {
  type: RELEASENOTES_ACTION_TYPES.UPDATE_RELEASENOTE;
  payload: { updateData: Record<string, string>; id: number };
}

export type ReleaseNotesAction =
  | IGetInitialReleaseNotesRequestAction
  | IGetInitialReleaseNotesSuccessAction
  | IGetInitialReleaseNotesErrorAction
  | IGetReleaseNotesRequestAction
  | IGetReleaseNotesSuccessAction
  | IDeleteReleaseNotesAction
  | ICreateReleaseNoteAction
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | ISetTableHiddenColumns
  | IChangeColumnsOrder
  | IUpdateReleaseNotesAction;
