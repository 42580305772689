import { VALIDATION_REGEX } from "Constants";
import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import * as Yup from "yup";

export type ValuesType = {
  password: string;
  confirmPassword: string;
};

export const initialValues: ValuesType = {
  password: "",
  confirmPassword: "",
};

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .matches(VALIDATION_REGEX.password, {
            message: validationErrors.unsafePassword,
          })
          .required(validationErrors.required),
        confirmPassword: Yup.string()
          .oneOf(
            [Yup.ref("password"), ""],
            validationErrors.passwordsDoNotMatch,
          )
          .required(validationErrors.required),
      }),
    [
      validationErrors.passwordsDoNotMatch,
      validationErrors.unsafePassword,
      validationErrors.required,
    ],
  );
  return schema;
};
