import * as Yup from "yup";

import { useValidationErrors } from "Hooks/validationErrors";
import { LOGIN_DTO } from "Types";
import { useMemo } from "react";

export const initialValues: LOGIN_DTO = {
  userName: "",
  password: "",
};

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        userName: Yup.string().required(validationErrors.required),
        password: Yup.string().required(validationErrors.required),
      }),
    [validationErrors.required],
  );
  return schema;
};
