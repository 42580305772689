import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useValidationErrors = () => {
  const [t] = useTranslation();

  const validationErrors = useMemo(
    () => ({
      required: t("general.validationErrors.fieldRequired"),
      passwordsDoNotMatch: t("general.validationErrors.passwordsDoNotMatch"),
      unsafePassword: t("general.validationErrors.unsafePassword"),
      maxLengthExceeded: ({ max }) =>
        `${t("general.validationErrors.maxLengthExceeded")} ${max}`,
      maxValueExceeded: ({ max }) =>
        `${t("general.validationErrors.maxValueExceeded")} ${max}`,
      belowMinValue: ({ min }) =>
        `${t("general.validationErrors.belowMinValue")} ${min}`,
      invalidEmailAddress: t("general.validationErrors.invalidEmailAddress"),
      date: t("general.validationErrors.date"),
      time: t("general.validationErrors.time"),
      phone: t("general.validationErrors.phone"),
      username: t("general.validationErrors.username"),
      timeWithSeconds: t("general.validationErrors.invalidTimeWithSeconds"),
    }),
    [t],
  );

  return validationErrors;
};
