import { CustomerOutside } from "@ero/app-common/models";
import { Description, Email, Person, Phone, Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FormCheckbox, Input, Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { type AppState } from "Store";
import {
  createCustomerPerson,
  resetMeta,
  updateCustomerPerson,
} from "Store/customerPersons";
import { getCustomersInitial } from "Store/customers/actions";
import { RequestBody } from "Types";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useValidationSchema } from "./formConfig";

interface ICreateCustomerEmployeeModal {
  person?: CustomerOutside["persons"][0];
  mainContactId?: number;
  isOpen: boolean;
  onClose: () => void;
}

export type ValuesType = RequestBody<"/customers/addPerson">;

export const PersonModal: React.FC<ICreateCustomerEmployeeModal> = ({
  person,
  mainContactId,
  onClose,
  isOpen,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useValidationSchema();

  const { success, loading } = useSelector(
    (store: AppState) => store.customerEmployees,
  );

  useEffect(() => {
    if (success) {
      dispatch(resetMeta());
      onClose();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, onClose]);

  const onSubmit = (values) => {
    if (person?._id) {
      dispatch(updateCustomerPerson(values, person._id));
    } else {
      dispatch(createCustomerPerson(values));
    }
    dispatch(getCustomersInitial({ page: -1, pageLength: -1 }));
  };

  const initialValues: ValuesType = {
    firstName: "",
    lastName: "",
    description: "",
    phone: "",
    mobile: "",
    email: "",
    mainContact:
      (person?._id && mainContactId && person?._id === mainContactId) || false,
    ...person,
  };

  return (
    <Dialog open={isOpen} onClose={onClose} scroll="body">
      <DialogTitle>
        {person?._id
          ? `${person.firstName} ${person.lastName}`
          : t("customerEmployees.create")}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ isValid, dirty, submitForm }) => (
          <>
            <DialogContent dividers>
              <Form>
                <Input
                  name="firstName"
                  size="medium"
                  label={t("general.fields.firstName")}
                  StartIcon={<Person />}
                  sx={{ mt: 1, mb: 1 }}
                  mandatory
                />
                <Input
                  name="lastName"
                  size="medium"
                  label={t("general.fields.lastName")}
                  StartIcon={<Person />}
                  sx={{ mt: 1, mb: 1 }}
                  mandatory
                />
                <Input
                  name="phone"
                  size="medium"
                  label={t("customers.fields.phone")}
                  StartIcon={<Phone />}
                  sx={{ mt: 1, mb: 1 }}
                />
                <Input
                  name="mobile"
                  size="medium"
                  label={t("customers.fields.mobile")}
                  StartIcon={<Phone />}
                  sx={{ mt: 1, mb: 1 }}
                />
                <Input
                  name="email"
                  size="medium"
                  label={t("general.fields.email")}
                  StartIcon={<Email />}
                  sx={{ mt: 1, mb: 1 }}
                />
                <Input
                  name="description"
                  size="medium"
                  label={t("general.fields.description")}
                  rows={10}
                  StartIcon={<Description />}
                  sx={{ mt: 1, mb: 1 }}
                />
                <FormCheckbox
                  name={"mainContact"}
                  label={t("customers.fields.mainContact")}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={!isValid || !dirty || loading}
                startIcon={!loading && <Save />}
              >
                {loading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
