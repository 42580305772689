import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { type AppState } from "Store";
import { resetMeta } from "Store/machines";
import { createMachine, updateMachine } from "Store/machines/actions";
import { type LocalMachineType } from "Store/machines/specs";
import { RequestBody } from "Types";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { useValidationSchema, ValuesType } from "./formConfig";
import { FormContent } from "./formContent";

interface IProps {
  isOpen: boolean;
  machine?: LocalMachineType;
  createMachine?: (data: RequestBody<"/machines/create">) => AnyAction;
  updateMachine?: (data: RequestBody<"/machines/update">) => AnyAction;
  getStoreState?: (store: AppState) => any;
  onClose: () => void;
  onSuccess?: () => void;
}

export const MachineInformationModal: React.FC<IProps> = ({
  isOpen,
  machine,
  onClose,
  onSuccess,
}) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useValidationSchema();

  const { success, loading } = useSelector((state: AppState) => state.machines);

  useEffect(() => {
    if (success) {
      if (onSuccess) onSuccess();
      dispatch(resetMeta());
      onClose();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, onSuccess, onClose]);

  const initialValues: ValuesType = useMemo(
    () => ({
      ...machine,
      manufacturer: machine?.manufacturer._id || -1,
      performance: machine?.performance || "",
      width: machine?.width || "",
      height: machine?.height || "",
      length: machine?.length || "",
      workingWidth: machine?.workingWidth || "",
      intact: machine?.intact === undefined ? 1 : +machine.intact,
      loadingCapacity: machine?.loadingCapacity || "",
      nextMaintenance: machine?.nextMaintenance || "",
      tuv: machine?.tuv || "",
      alignment: machine?.alignment || [],
      kind: machine?.kind || "",
    }),
    [machine],
  );

  const handleFormSubmit = useCallback(
    (values) => {
      const { avatar, manufacturer, ...update } = values;
      const defaultEmptyValue = machine?._id ? null : undefined;
      if (avatar && avatar.indexOf("data:image") === 0) {
        update.avatar = avatar;
      }
      if (update.performance === "") {
        update.performance = defaultEmptyValue;
      }
      if (update.width === "") {
        update.width = defaultEmptyValue;
      }
      if (update.height === "") {
        update.height = defaultEmptyValue;
      }
      if (update.length === "") {
        update.length = defaultEmptyValue;
      }
      if (update.workingWidth === "") {
        update.workingWidth = defaultEmptyValue;
      }
      if (update.loadingCapacity === "") {
        update.loadingCapacity = defaultEmptyValue;
      }
      if (update.nextMaintenance === "") {
        update.nextMaintenance = defaultEmptyValue;
      }
      if (update.tuv === "") {
        update.tuv = defaultEmptyValue;
      }
      if (manufacturer !== -1) {
        update.manufacturer = manufacturer;
      }
      update.intact = !!update.intact;
      if (machine?._id) {
        dispatch(updateMachine(update, machine._id));
      } else {
        dispatch(createMachine(update));
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [machine, createMachine],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      scroll="body"
    >
      <DialogTitle>
        {machine?._id ? machine.name : t("machines.create")}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid }) => (
          <Form>
            <DialogContent dividers>
              <FormContent />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid || !dirty || loading}
                startIcon={!loading && <Save />}
              >
                {loading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
