import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import { type Action, type UnknownAction } from "redux";
import {
  ColumnFilterDataType,
  type CREATE_MACHINE_DTO,
  type GetTableDataParams,
} from "Types";
import { type LocalMachineType } from "./specs";

export enum MACHINES_ACTION_TYPES {
  CHANGE_COLUMNS_ORDER = "machines/change-columns-order",
  CREATE_MACHINE_REQUEST = "machines/create-machine-request",
  DELETE_MACHINES_REQUEST = "machines/delete-machines-request",
  GET_INITIAL_MACHINES_ERROR = "machines/get-initial-machines-error",
  GET_INITIAL_MACHINES_REQUEST = "machines/get-initial-machines-request",
  GET_INITIAL_MACHINES_SUCCESS = "machines/get-initial-machines-success",
  GET_MACHINES_REQUEST = "machines/get-machines-request",
  GET_MACHINES_SUCCESS = "machines/get-machines-success",
  RESET_META = "machines/reset-meta",
  RESET_SEARCH = "machines/reset-search",
  SET_ERROR = "machines/set-error",
  SET_LOADING = "machines/set-loading",
  SET_SUCCESS = "machines/set-success",
  SET_TABLE_HIDDEN_COLUMNS = "machines/set-table-hidden-columns",
  UPDATE_MACHINE_REQUEST = "machines/update-machine-request",
}

export interface ISetLoadingAction extends Action {
  type: MACHINES_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: MACHINES_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: MACHINES_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends UnknownAction {
  type: MACHINES_ACTION_TYPES.RESET_META;
}

export interface IResetSearchAction extends UnknownAction {
  type: MACHINES_ACTION_TYPES.RESET_SEARCH;
}

export interface IGetInitialMachinesRequestAction extends UnknownAction {
  type: MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_REQUEST;
  payload: {
    params: GetTableDataParams;
  };
}
export interface IGetInitialMachinesSuccessAction extends Action {
  type: MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_SUCCESS;
  payload: {
    machines: LocalMachineType[];
    maxCount: number;
  };
}

export interface IGetInitialMachinesErrorAction extends Action {
  type: MACHINES_ACTION_TYPES.GET_INITIAL_MACHINES_ERROR;
}

export interface IGetMachinesRequestAction extends UnknownAction {
  type: MACHINES_ACTION_TYPES.GET_MACHINES_REQUEST;
  payload: {
    params: GetTableDataParams;
    withSuccess?: boolean;
    filters?: ColumnFilterDataType[];
    search?: SearchSpec;
  };
}

export interface IGetMachinesSuccessAction extends Action {
  type: MACHINES_ACTION_TYPES.GET_MACHINES_SUCCESS;
  payload: {
    filters?: ColumnFilterDataType[];
    machines: LocalMachineType[];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
  };
}

export interface ICreateMachineRequestAction extends UnknownAction {
  type: MACHINES_ACTION_TYPES.CREATE_MACHINE_REQUEST;
  payload: {
    machine: CREATE_MACHINE_DTO;
  };
}

export interface IDeleteMachinesRequestAction extends UnknownAction {
  type: MACHINES_ACTION_TYPES.DELETE_MACHINES_REQUEST;
  payload: {
    ids: {
      ids: number[];
    };
  };
}

export interface IUpdateMachineRequestAction extends UnknownAction {
  type: MACHINES_ACTION_TYPES.UPDATE_MACHINE_REQUEST;
  payload: {
    machine: any;
    id: number;
  };
}

export interface ISetTableHiddenColumns extends UnknownAction {
  type: MACHINES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { hiddenColumns: Record<string, boolean> };
}

export interface IChangeColumnsOrder extends Action {
  type: MACHINES_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export type MachinesAction =
  | IChangeColumnsOrder
  | ICreateMachineRequestAction
  | IDeleteMachinesRequestAction
  | IGetInitialMachinesErrorAction
  | IGetInitialMachinesRequestAction
  | IGetInitialMachinesSuccessAction
  | IGetMachinesRequestAction
  | IGetMachinesSuccessAction
  | IResetMetaAction
  | IResetSearchAction
  | ISetErrorAction
  | ISetLoadingAction
  | ISetSuccessAction
  | ISetTableHiddenColumns
  | IUpdateMachineRequestAction;
