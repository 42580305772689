import { MAX_LENGTH, VALIDATION_REGEX } from "Constants";
import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import * as Yup from "yup";

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        label: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        companyName: Yup.string()
          .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded)
          .required(validationErrors.required),
        street: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        zipCode: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        city: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        phone: Yup.string().matches(
          VALIDATION_REGEX.phone,
          validationErrors.phone,
        ),
        mobile: Yup.string().matches(
          VALIDATION_REGEX.phone,
          validationErrors.phone,
        ),
        email: Yup.string()
          .matches(VALIDATION_REGEX.email, validationErrors.invalidEmailAddress)
          .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded),
        notes: Yup.string().max(
          MAX_LENGTH.LONG_STRING,
          validationErrors.maxLengthExceeded,
        ),
      }),
    [
      validationErrors.maxLengthExceeded,
      validationErrors.phone,
      validationErrors.required,
      validationErrors.invalidEmailAddress,
    ],
  );
  return schema;
};
