import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useValidationErrors } from "Hooks/validationErrors";
import { successToast } from "Services";
import { Field, Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface OneFieldFormSelectProps {
  name: string;
  label: string;
  value: number | string | undefined;
  options: { label: string; value: number | string }[];
  validationRules?: Yup.StringSchema | Yup.NumberSchema;
  onSubmit: (values: { [name: string]: any }) => void;
  success: boolean;
}

export const OneFieldFormSelect: React.FC<OneFieldFormSelectProps> = ({
  name,
  label,
  value,
  options,
  validationRules,
  onSubmit,
  success,
}) => {
  const [t] = useTranslation();

  const validationErrors = useValidationErrors();

  const [showToastOnSuccess, setShowToastOnSuccess] = useState(false);

  const initialValues = useMemo(
    () => ({ [name]: value?.toString() || "" }),
    [name, value],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [name]:
          validationRules || Yup.string().required(validationErrors.required),
      }),
    [name, validationErrors.required, validationRules],
  );

  const submitHandler = useCallback(
    (values: { [name: string]: string }): void => {
      setShowToastOnSuccess(true);
      onSubmit(values);
    },
    [onSubmit],
  );

  useEffect(() => {
    if (showToastOnSuccess && success) {
      successToast(t("general.buttons.saved"));
      setShowToastOnSuccess(false);
    }
  }, [success, showToastOnSuccess, t]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form style={{ width: "100%" }}>
          <Field name={name}>
            {({ field, form: { isValid, dirty, resetForm }, meta }) => (
              <Stack direction="row" spacing={2} alignItems="center">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    {label}
                  </InputLabel>
                  <Select
                    {...field}
                    label={label}
                    error={!!meta.error}
                    variant="outlined"
                    fullWidth
                  >
                    {options.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
                </FormControl>
                {isValid && dirty && (
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      type="submit"
                      disabled={!isValid || !dirty}
                      color={isValid && dirty ? "success" : "default"}
                    >
                      <CheckIcon />
                    </IconButton>
                    <Divider orientation="vertical" flexItem />
                    <IconButton
                      disabled={!isValid || !dirty}
                      onClick={() => {
                        resetForm();
                      }}
                      color={isValid && dirty ? "error" : "default"}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            )}
          </Field>
        </Form>
      </Formik>
    </>
  );
};
