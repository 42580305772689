import { TimeSlotOutside } from "@ero/app-common/models/TimeSlot";
import { Milliseconds } from "@ero/app-common/util/Milliseconds";
import { JobResponseBody } from "@ero/app-common/v2/routes/models/job";

export type MarkedEventType = {
  id: number;
  dayRange: {
    start: Milliseconds;
    end: Milliseconds;
  };
  selectedResource: string;
};

export const getResourceChanges = (
  newResources: any[],
  oldResources?: any[],
) => {
  let assignedDrivers: number[] = [];
  const unassignedDrivers: number[] = [];

  const mappedNewResources = newResources
    .filter((resource) => resource.id !== "0")
    .map((resource) => Number(resource.id));
  const mappedOldResources = (oldResources || [])
    .filter((resource) => resource.id !== "0")
    .map((resource) => Number(resource.id));
  if (!mappedOldResources?.length) {
    assignedDrivers = mappedNewResources;
  } else {
    mappedNewResources.forEach((resource) => {
      if (!mappedOldResources.includes(resource)) {
        assignedDrivers.push(resource);
      }
    });
    mappedOldResources.forEach((resource) => {
      if (!mappedNewResources.includes(resource)) {
        unassignedDrivers.push(resource);
      }
    });
  }

  return {
    assignedDrivers,
    unassignedDrivers,
  };
};

export const getResourceIds = (job: JobResponseBody) => {
  const result: string[] =
    job.employees?.map((employee) => `${employee._id}`) || [];

  return result;
};

export const isTimeslotOverlapping = (
  initialTimeslot: TimeSlotOutside,
  employeeTimeslots: Record<number, TimeSlotOutside>,
) => {
  let isOverlapping = false;

  Object.values(employeeTimeslots).forEach((timeslot) => {
    if (timeslot._id === initialTimeslot._id) {
      return;
    }

    if (
      timeslot.start <= initialTimeslot.end &&
      timeslot.end >= initialTimeslot.start
    ) {
      isOverlapping = true;
    }
  });

  return isOverlapping;
};
