import { PERM } from "@ero/app-common/enums";
import {
  Badge,
  Engineering,
  FmdGood,
  Group,
  Person,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
} from "@mui/material";
import {
  getCustomersV2,
  getEmployeesV2,
  getMachinesV2,
  getParcels,
  getServicesV2,
} from "Api";
import MachineSvg from "Assets/icons/machine.svg?react";
import { CloseButton } from "Components/closeButton/closeButton";
import { ROUTES } from "Constants";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AppState } from "Store";
import { OnboardingItem } from "./components/OnboardingItem";

const apiCatch = (ex: unknown) => {
  console.error(ex);
  return { data: [] };
};

export const OnboardingModal: React.FC = () => {
  const [t] = useTranslation();

  const { addressAndStreetNumber, city, zipCode, location } = useSelector(
    (state: AppState) => state.auth.companyData,
  );

  const companyInformationAvailable = useMemo(
    () => !!addressAndStreetNumber && !!city && !!zipCode && !!location,
    [addressAndStreetNumber, city, location, zipCode],
  );

  const [servicesAvailable, setServicesAvailable] = useState(true);
  const [customersAvailable, setCustomersAvailable] = useState(true);
  const [machinesAvailable, setMachinesAvailable] = useState(true);
  const [driversAvailable, setDriversAvailable] = useState(true);
  const [parcelsAvailable, setParcelsAvailable] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => void setIsOpen(false), []);

  useEffect(() => {
    (async () => {
      const servicesPromise = getServicesV2({ limit: 1 }).catch(apiCatch);
      const customersPromise = getCustomersV2({ limit: 1 }).catch(apiCatch);
      const machinesPromise = getMachinesV2({ limit: 1 }).catch(apiCatch);
      const driversPromise = getEmployeesV2({
        limit: 1,
        type: PERM.DRIVER,
      }).catch(apiCatch);
      const parcelsPromise = getParcels({ limit: 1 }).catch(apiCatch);
      const [
        { data: services },
        { data: customers },
        { data: machines },
        { data: drivers },
        { data: parcels },
      ] = await Promise.all([
        servicesPromise,
        customersPromise,
        machinesPromise,
        driversPromise,
        parcelsPromise,
      ]);
      setServicesAvailable(services.length > 0);
      setCustomersAvailable(customers.length > 0);
      setMachinesAvailable(machines.length > 0);
      setDriversAvailable(drivers.length > 0);
      setParcelsAvailable(parcels.length > 0);
      setIsOpen(
        !companyInformationAvailable ||
          services.length == 0 ||
          customers.length == 0 ||
          machines.length == 0 ||
          drivers.length == 0 ||
          parcels.length == 0,
      );
    })();
  }, [companyInformationAvailable]);

  return (
    <Dialog fullWidth maxWidth={"sm"} open={isOpen} onClose={close}>
      <CloseButton onClose={close} />
      <DialogTitle>{t("onboarding.title")}</DialogTitle>
      <DialogContent dividers>
        <Typography>{t("onboarding.text")}</Typography>
        <List sx={{ py: 4 }}>
          <OnboardingItem
            icon={<Person />}
            title={t("profile.companyInformation.title")}
            route={ROUTES.PROFILE.VIEW}
            isSetUp={companyInformationAvailable}
          />
          <OnboardingItem
            icon={<Engineering />}
            title={t("general.navigation.positions", { count: 2 })}
            route={ROUTES.MAIN.SERVICES}
            isSetUp={servicesAvailable}
          />
          <OnboardingItem
            icon={<Group />}
            title={t("general.navigation.customers", { count: 2 })}
            route={ROUTES.MAIN.CUSTOMERS}
            isSetUp={customersAvailable}
          />
          <OnboardingItem
            icon={<MachineSvg style={{ fontSize: 16, strokeWidth: 1.5 }} />}
            title={t("general.navigation.machines", { count: 2 })}
            route={ROUTES.MAIN.MACHINES}
            isSetUp={machinesAvailable}
          />
          <OnboardingItem
            icon={<Badge />}
            title={t("employees.permission.driver", { count: 2 })}
            route={ROUTES.MAIN.EMPLOYEES}
            isSetUp={driversAvailable}
          />
          <OnboardingItem
            icon={<FmdGood />}
            title={t("general.navigation.parcels")}
            route={ROUTES.MAIN.PARCELS}
            isSetUp={parcelsAvailable}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t("general.buttons.close")}</Button>
      </DialogActions>
    </Dialog>
  );
};
