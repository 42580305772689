import { Box, Stack, Typography } from "@mui/material";
import Logo from "Assets/Logo_EROplan_RGB.png";
import LogoNegativ from "Assets/Logo_EROplan_negativ_RGB.png";
import { DarkModeSwitch, LangChooseSwitch } from "Components";
import { ROUTES } from "Constants";
import { useQueryParams } from "Hooks/queryParams";
import { AppState } from "Store";
import { resetMeta, verifyPassword } from "Store/auth";
import { type VERIFY_PASS_DTO } from "Types";
import { Formik } from "formik";
import { Base64 } from "js-base64";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { initialValues, useValidationSchema } from "./formConfig";
import { FormContent } from "./formContent";

export const VerifyPassword: React.FC = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useValidationSchema();

  const { colorMode, success } = useSelector((state: AppState) => state.auth);

  const { reset, invitation } = useQueryParams("reset", "invitation");

  useEffect(() => {
    return (): void => {
      dispatch(resetMeta());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const handleFormSubmit = (values: VERIFY_PASS_DTO) => {
    const credentials = {
      userName: Base64.decode(invitation ?? ""),
      password: values.password,
    };
    dispatch(verifyPassword(credentials));
  };

  if (success) {
    return <Navigate to={ROUTES.AUTH.RESET_PASSWORD} replace />;
  }

  if (reset || !invitation) {
    return <Navigate to={ROUTES.AUTH.LOGIN} />;
  }

  return (
    <>
      <img
        src={colorMode === "light" ? Logo : LogoNegativ}
        alt="logo"
        style={{ maxWidth: "100%", padding: 32 }}
      />
      <Box>
        <Stack direction={"row"} justifyContent={"center"} spacing={2} pb={2}>
          <LangChooseSwitch />
          <DarkModeSwitch />
        </Stack>
        <Typography variant="h4" textAlign="center" pb={2}>
          {t("auth.verifyPassword.title")}
        </Typography>
        <Typography variant="body1" textAlign="center" pb={2}>
          {t("auth.verifyPassword.subtitle")}
        </Typography>
      </Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        component={FormContent}
      />
    </>
  );
};
