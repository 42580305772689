import { MAX_LENGTH, VALIDATION_REGEX } from "Constants";
import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import * as Yup from "yup";

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();

  const schema = useMemo(
    () => ({
      freeField: Yup.string()
        .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded)
        .required(validationErrors.required),
      phone: Yup.string()
        .matches(VALIDATION_REGEX.phone, validationErrors.phone)
        .required(validationErrors.required),
      unitOfMeasurement: Yup.string()
        .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded)
        .required(validationErrors.required),
    }),
    [
      validationErrors.maxLengthExceeded,
      validationErrors.phone,
      validationErrors.required,
    ],
  );

  return schema;
};
