import { IconButton, SxProps, Theme } from "@mui/material";
import { type InputProps } from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { type SizeType } from "Types";
import { useField, type FieldConfig } from "formik";
import React, { forwardRef, useCallback, useMemo } from "react";

export interface IInput extends FieldConfig {
  autoFocus?: boolean;
  type?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  StartIcon?: JSX.Element;
  EndIcon?: JSX.Element;
  onIconClick?: () => void;
  size?: SizeType;
  multiline?: boolean;
  rows?: number;
  inputProps?: InputProps;
  mandatory?: boolean;
  className?: string;
  onBlur?: () => void;
  onChange?: () => void;
  onClick?: () => void;
  autoComplete?: string;
  sx?: SxProps<Theme>;
}

const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      autoFocus,
      type = "text",
      label,
      placeholder,
      disabled = false,
      StartIcon,
      EndIcon,
      multiline = false,
      rows = 7,
      inputProps: customInputProps,
      mandatory,
      className,
      onBlur,
      onChange,
      onClick,
      onIconClick,
      sx,
      ...rest
    },
    ref?,
  ) => {
    const [field, meta] = useField(rest.name);
    const isError = useMemo(
      () => meta.touched && !!meta.error,
      [meta.error, meta.touched],
    );

    const inputProps: InputProps = useMemo(
      () => ({
        ...customInputProps,
      }),
      [customInputProps],
    );

    const inputLabelProps = useMemo(() => {
      const inputLabelProps = {
        shrink: true,
      };

      if (StartIcon) {
        inputProps.startAdornment = (
          <InputAdornment position="start">{StartIcon}</InputAdornment>
        );
      }

      if (EndIcon) {
        const endAdornment = (
          <InputAdornment position="end">
            {typeof onIconClick === "function" ? (
              <IconButton onClick={onIconClick} size="small">
                {EndIcon}
              </IconButton>
            ) : (
              <>{EndIcon}</>
            )}
          </InputAdornment>
        );

        inputProps.endAdornment = endAdornment;
      }
      return inputLabelProps;
    }, [EndIcon, StartIcon, inputProps, onIconClick]);

    const combinedOnBlur = useCallback(
      (event) => {
        field.onBlur(event);
        if (onBlur) onBlur();
      },
      [field, onBlur],
    );

    const combinedOnChange = useCallback(
      (event) => {
        if (rest.name === "userName") {
          event.target.value = event.target.value.trim();
        }

        field.onChange(event);
        if (onChange !== undefined) {
          onChange();
        }
      },
      [field, onChange, rest.name],
    );

    return (
      <TextField
        {...field}
        sx={sx}
        autoFocus={autoFocus}
        error={isError}
        disabled={disabled}
        label={label}
        required={mandatory}
        placeholder={placeholder}
        type={type}
        variant="outlined"
        fullWidth
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
        multiline={multiline}
        rows={rows}
        inputRef={ref}
        helperText={meta.touched && meta.error}
        className={className}
        onBlur={combinedOnBlur}
        onChange={combinedOnChange}
        onClick={onClick}
        autoComplete="off"
      />
    );
  },
);

Input.displayName = "Input";

export { Input };
