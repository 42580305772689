import { deltaReturn } from "@iridium-works/json-delta";
import { type HasId, type WsMessage } from "Types";

export const mapEntityAfterSocketUpdate = <T extends HasId>(
  item: T,
  message: WsMessage,
) => {
  const isUpdatedItem = item._id === message.meta;

  if (!isUpdatedItem) {
    return item;
  }

  return deltaReturn(item, message.payload);
};
