import { getParcels } from "Api";
import { OptionType } from "Types";
import { sortByLabel } from "Utils/options";

export const useAllParcelOptions = (customer: number) => {
  const fetchParcelOptions = async (): Promise<OptionType[]> => {
    try {
      const { data } = await getParcels({ customer });
      return data
        .map((parcel) => ({
          value: parcel._id,
          label: `${parcel.name}`,
          meta: { color: parcel.grapeVariety?.color },
        }))
        .sort(sortByLabel);
    } catch {
      return [];
    }
  };

  return {
    fetchParcelOptions,
  };
};
