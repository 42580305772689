import { AttachFile, Error } from "@mui/icons-material";
import { Box, Chip, Stack, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FieldConfig, useField } from "formik";
import React, { useCallback, useRef } from "react";

interface FileInputConfig extends FieldConfig {
  label: string;
  buttonlabel: string;
  accept: string[];
  disabled?: boolean;
  starticon?: JSX.Element;
  endicon?: JSX.Element;
}

export const FileInput: React.FC<FileInputConfig> = (props) => {
  const { label, buttonlabel, accept, disabled, starticon, endicon, ...rest } =
    props;

  const [field, meta, helpers] = useField(rest.name);

  const ref = useRef<HTMLInputElement>(null);

  const clearField = useCallback(() => {
    helpers.setValue(undefined);
    if (ref.current) {
      ref.current.value = "";
    }
  }, [helpers]);

  return (
    <Stack direction="row" sx={{ justifyContent: "space-between" }}>
      <Typography variant="subtitle1">{label}</Typography>
      <Box>
        {!field.value && (
          <label htmlFor={field.name}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              disabled={disabled}
              startIcon={starticon}
              endIcon={endicon}
            >
              {buttonlabel}
            </Button>
          </label>
        )}
        {field.value && (
          <Tooltip title={field.value.name}>
            <Chip
              label={field.value.name}
              onDelete={() => clearField()}
              icon={<AttachFile />}
              sx={{
                maxWidth: "300px",
              }}
            />
          </Tooltip>
        )}
        <input
          {...field}
          id={field.name}
          type="file"
          accept={accept.join(",")}
          disabled={disabled}
          ref={ref}
          value={undefined}
          onChange={(event) => {
            if (
              event.currentTarget &&
              event.currentTarget.files &&
              event.currentTarget.files[0]
            ) {
              helpers.setValue(event.currentTarget.files[0]);
            }
          }}
          style={{ visibility: "hidden", width: 0 }}
        />
        {meta.error && meta.touched && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Error color="error" />
            <Typography variant="caption" color="error">
              {meta.error}
            </Typography>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};
