import {
  getJobs,
  setCalendarCurrentView,
  setCalendarCurrentViewRange,
} from "Store/planning";
import { type CalendarDateRange, type InitialScreenMetaType } from "Types";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

interface IUseDate {
  calendarCurrentView: string;
  dateRange: CalendarDateRange;
  displayedDateRange: CalendarDateRange;
  initialMeta: InitialScreenMetaType;
  loading: boolean;
}

export const useDate = (props: IUseDate) => {
  const dispatch = useDispatch();

  const {
    calendarCurrentView,
    dateRange,
    displayedDateRange,
    initialMeta,
    loading,
  } = props;

  const onDateSet = useCallback(
    (params) => {
      if (loading || initialMeta.loading) {
        return;
      }

      if (calendarCurrentView !== params.view.type) {
        dispatch(setCalendarCurrentView(params.view.type));
      }

      const newStart = dayjs(params.start);
      const newEnd = dayjs(params.end).subtract(1, "millisecond");

      const newStartNum = newStart.valueOf();
      const newEndNum = newEnd.valueOf();

      if (
        displayedDateRange.start !== newStartNum &&
        displayedDateRange.end !== newEndNum
      ) {
        dispatch(
          setCalendarCurrentViewRange({ start: newStartNum, end: newEndNum }),
        );
      }

      const requestDateRange = {
        start: +newStart.format("x"),
        end: +newEnd.format("x"),
      };
      let newDateRange = { ...dateRange };
      let replace = false;

      /*
       */
      if (newStart.isBefore(dateRange.start) && newEnd.isAfter(dateRange.end)) {
        newDateRange = requestDateRange;
        replace = true;
      } else if (newStart.isBefore(dateRange.start)) {
        newDateRange.start = requestDateRange.start;
      } else if (newEnd.isAfter(dateRange.end)) {
        newDateRange.end = requestDateRange.end;
      } else {
        return;
      }

      dispatch(
        getJobs({
          requestDates: requestDateRange,
          dateRange: newDateRange,
          replace,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dateRange,
      displayedDateRange.start,
      displayedDateRange.end,
      loading,
      initialMeta.loading,
      calendarCurrentView,
    ],
  );

  return { onDateSet };
};
