import { TimePicker, TimePickerProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useField } from "formik";
import React, { forwardRef, useCallback, useState } from "react";

interface IDurationInput extends TimePickerProps<Dayjs> {
  name: string;
  label: string;
  format?: string;
  required?: boolean;
}

const DurationInput = forwardRef<HTMLInputElement, IDurationInput>(
  (props, ref) => {
    const { name, label, format, required } = props;

    const [field, meta, helpers] = useField(name);

    const [dayJsValue, setDayJsValue] = useState<Dayjs | null>(
      !field.value ? null : dayjs().startOf("day").add(field.value),
    );

    const onChange = useCallback(
      (dayJsValue: Dayjs | null) => {
        const start = dayJsValue?.startOf("day");
        const difference = dayJsValue?.diff(start);

        setDayJsValue(dayJsValue);
        helpers.setValue(difference);
      },
      [helpers],
    );

    return (
      <TimePicker
        {...props}
        ref={ref}
        label={label}
        format={format}
        value={dayJsValue}
        onChange={onChange}
        slotProps={{
          textField: {
            required,
            error: meta.touched && !!meta.error,
            helperText: meta.touched && meta.error,
            fullWidth: true,
          },
        }}
      />
    );
  },
);

DurationInput.displayName = "DurationInput";

export { DurationInput };
