import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useField } from "formik";
import React, { useCallback, useState } from "react";

type Props = {
  name: string;
  label: string;
  format?: string;
  required?: boolean;
  minDate?: dayjs.Dayjs;
};

export const DateTimeInput = React.forwardRef<HTMLInputElement, Props>(
  ({ name, label, format, required, minDate }, ref) => {
    const [field, meta, helpers] = useField(name);

    const [dayJsValue, setDayJsValue] = useState<Dayjs | null>(
      !field.value ? null : dayjs(field.value),
    );

    const onChange = useCallback(
      (dayJsValue: Dayjs | null) => {
        setDayJsValue(dayJsValue);
        helpers.setValue(dayJsValue?.valueOf());
      },
      [helpers],
    );

    return (
      <DateTimePicker
        value={dayJsValue}
        onChange={onChange}
        ref={ref}
        slotProps={{
          textField: {
            required,
            error: meta.touched && !!meta.error,
            helperText: meta.touched && meta.error,
            fullWidth: true,
          },
        }}
        format={format}
        label={label}
        minDate={minDate}
      />
    );
  },
);

DateTimeInput.displayName = "DateTimeInput";
