import { MAX_LENGTH, VALIDATION_REGEX } from "Constants";
import { useValidationErrors } from "Hooks/validationErrors";
import { useMemo } from "react";
import * as Yup from "yup";

export const useValidationSchema = () => {
  const validationErrors = useValidationErrors();
  const schema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .max(MAX_LENGTH.DEFAULT, validationErrors.maxLengthExceeded)
          .required(validationErrors.required),
        serviceKind: Yup.number().required(validationErrors.required),
        billingUnit: Yup.number().required(validationErrors.required),
        additionalName: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        description: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          validationErrors.maxLengthExceeded,
        ),
        cost: Yup.number()
          .min(0, validationErrors.belowMinValue)
          .max(MAX_LENGTH.NUMBER, validationErrors.maxValueExceeded)
          .required(validationErrors.required),
        difficulty: Yup.string()
          .matches(
            VALIDATION_REGEX.durationWithSeconds,
            validationErrors.timeWithSeconds,
          )
          .required(validationErrors.required),
        notes: Yup.string(),
      }),
    [
      validationErrors.maxLengthExceeded,
      validationErrors.maxValueExceeded,
      validationErrors.belowMinValue,
      validationErrors.required,
      validationErrors.timeWithSeconds,
    ],
  );
  return schema;
};
