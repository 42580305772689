import { LANGUAGE } from "@ero/app-common/enums/language";
import { UserResponseBody } from "@ero/app-common/v2/routes/models/user";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import { Box, LinearProgress, useTheme } from "@mui/material";
import { ROUTES } from "Constants";
import dayjs from "dayjs";
import { CalendarSlotDuration } from "Enums";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { useEventDraggingContext } from "../../contexts/EventDraggingContext";
import { OrderDetailsModal } from "../orderDetailsModal/orderDetailsModal";
import "./calendar.css";
import { DriverPlaceholder } from "./components/driverPlaceholder/driverPlaceholder";
import { Toolbar } from "./components/toolbar/toolbar";
import { useEvents } from "./hooks/useEvents";
import { useResources } from "./hooks/useResources";

const slotDurations = {
  [CalendarSlotDuration.FIVE_MINUTES]: 5 * 60 * 1000,
  [CalendarSlotDuration.FIVETEEN_MINUTES]: 15 * 60 * 1000,
  [CalendarSlotDuration.THIRTY_MINUTES]: 30 * 60 * 1000,
  [CalendarSlotDuration.ONE_HOUR]: 60 * 60 * 1000,
};
const slotLabelIntervals = {
  [CalendarSlotDuration.FIVE_MINUTES]: 15 * 60 * 1000,
  [CalendarSlotDuration.FIVETEEN_MINUTES]: 60 * 60 * 1000,
  [CalendarSlotDuration.THIRTY_MINUTES]: 60 * 60 * 1000,
  [CalendarSlotDuration.ONE_HOUR]: 2 * 60 * 60 * 1000,
};

const defaultScrollTime = "06:00:00";

export const Calendar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const { businessHours } = useEventDraggingContext();

  const calendarRef = useRef<FullCalendar>(null);

  const [patialOrderDetails, setPartialOrderDetails] = useState<
    | {
        eventId: number | undefined;
        orderId: number;
      }
    | undefined
  >(undefined);

  const userLanguage = useSelector(
    (state: AppState) => state.auth.selectedLang,
  );

  const {
    date,
    users: allUsers,
    selectedUsers,
    sortedUsers,
    loadingEvents,
    slotDuration,
    scrollTime,
  } = useSelector((state: AppState) => state.planningV2);

  const {
    calendarEvents,
    eventChange,
    eventClick,
    eventDragStart,
    eventDragStop,
    eventResizeStart,
    eventResizeStop,
    eventReceive,
    getEventContent,
  } = useEvents(setPartialOrderDetails);

  const locales = useMemo(
    () => [userLanguage == LANGUAGE.EN ? { code: "en" } : { code: "de" }],
    [userLanguage],
  );

  const users: UserResponseBody[] = useMemo(
    () =>
      allUsers
        .filter((user) => !selectedUsers || selectedUsers.includes(user._id))
        .sort(
          (a, b) =>
            sortedUsers.findIndex((id) => id === a._id) -
            sortedUsers.findIndex((id) => id === b._id),
        ),
    [allUsers, selectedUsers, sortedUsers],
  );

  const scrollTimeFormatted = useMemo(
    () =>
      scrollTime ? dayjs(scrollTime).format("HH:mm:ss") : defaultScrollTime,
    [scrollTime],
  );

  const { resources, getResourceLabelContent } = useResources(users);

  const closeOrderDetailsModal = useCallback(() => {
    setPartialOrderDetails(undefined);
    dispatch(sagaActions.fetchEvents());
    dispatch(sagaActions.fetchOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateChange = useCallback((date: Date) => {
    calendarRef.current?.getApi().gotoDate(date);
  }, []);

  const onAddDriverClick = useCallback(() => {
    navigate(ROUTES.MAIN.EMPLOYEES, { state: { create: true } });
  }, [navigate]);

  useEffect(() => {
    dispatch(sagaActions.fetchUsers());
    dispatch(sagaActions.fetchEvents());

    return () => {
      dispatch(sagaActions.resetHighlights());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexFlow: "column",
      }}
    >
      <Toolbar onDateChange={onDateChange} />
      <Box height={"4px"}>{loadingEvents && <LinearProgress />}</Box>
      <Box flex={1} position={"relative"} className="planningV2">
        {allUsers.length === 0 && (
          <DriverPlaceholder onAddBtnClick={onAddDriverClick} />
        )}
        <FullCalendar
          schedulerLicenseKey="0921855681-fcs-1737021383"
          plugins={[
            resourceTimeGridPlugin,
            interactionPlugin,
            scrollGridPlugin,
          ]}
          initialView="resourceTimeGridDay"
          headerToolbar={{ center: "", left: "", right: "" }}
          height="100%"
          allDaySlot={false}
          resourceOrder={"index"}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
          }}
          dayMinWidth={240}
          eventBackgroundColor={theme.palette.primary.main}
          eventBorderColor={theme.palette.primary.main}
          droppable={!loadingEvents}
          editable={!loadingEvents}
          nowIndicator
          ref={calendarRef}
          locales={locales}
          initialDate={date}
          slotLabelInterval={
            slotLabelIntervals[slotDuration] ??
            slotLabelIntervals[CalendarSlotDuration.FIVETEEN_MINUTES]
          }
          slotDuration={
            slotDurations[slotDuration] ??
            slotDurations[CalendarSlotDuration.FIVETEEN_MINUTES]
          }
          expandRows
          resources={resources}
          resourceLabelContent={getResourceLabelContent}
          eventChange={eventChange}
          eventDragStart={eventDragStart}
          eventDragStop={eventDragStop}
          eventResizeStart={eventResizeStart}
          eventResizeStop={eventResizeStop}
          eventReceive={eventReceive}
          eventClick={eventClick}
          events={calendarEvents}
          eventContent={getEventContent}
          businessHours={businessHours}
          eventOverlap={false}
          scrollTime={scrollTimeFormatted}
        />
        {patialOrderDetails && (
          <OrderDetailsModal
            orderId={patialOrderDetails.orderId}
            highlightedEventId={patialOrderDetails.eventId}
            open={true}
            onClose={closeOrderDetailsModal}
          />
        )}
      </Box>
    </Box>
  );
};
